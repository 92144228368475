import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import AIZWEILOGO from 'assets/imgs/aizwei-charts-logo.svg';
import RECTANGLEGRADIENT from 'assets/imgs/rectangle-gradient.svg';
import type { Plot } from 'playground/interfaces/playground';
import { useEffect, useLayoutEffect, useState, type ReactElement } from 'react';
import './Chart.scss';

interface FeatureImportanceData {
  key: string;
  weak?: number;
  strong?: number;
  moderate?: number;
  opacity?: number;
}

interface ChartProps {
  isChat: boolean;
  data?: Plot;
  chartID?: string;
}

function calculateOpacity(
  absoluteImportanceValue: number,
  minImportance: number,
  maxImportance: number
): number {
  return (
    0.3 +
    0.7 *
      ((absoluteImportanceValue - minImportance) /
        (maxImportance - minImportance))
  );
}

function getCorrelationType(absoluteImportanceValue: number): string {
  return absoluteImportanceValue < 0.3
    ? 'weak'
    : absoluteImportanceValue >= 0.7
    ? 'strong'
    : 'moderate';
}

const Chart = (props: ChartProps): ReactElement => {
  const [data, setData] = useState<FeatureImportanceData[]>([]);
  const [titles, setTitles] = useState<string[]>([]);
  const [divID, setDivID] = useState<string>();
  const [maxImportance, setMaxImportance] = useState(-Infinity);
  const [minImportance, setMinImportance] = useState(Infinity);

  const colors = {
    strong: am5.color(0x02044c),
    moderate: am5.color(0x0f46b1),
    weak: am5.color(0x007682),
    tooltipBackground: am5.color(0x667085),
    tooltipText: am5.color(0xffffff)
  };

  useEffect(() => {
    if (
      props?.data?.featureNames?.length !== undefined &&
      props?.data?.featureImportance?.length !== undefined &&
      props?.data?.featurePlot?.length !== undefined
    ) {
      // feature_plot is an array of booleans that indicates which features are being plotted
      const featurePlot = props.data.featurePlot;
      const newData: FeatureImportanceData[] = [];
      let maxImp = -Infinity;
      let minImp = Infinity;

      // Determine the max and min absolute importance values
      props.data.featureImportance.forEach((importance) => {
        const absValue = Math.abs(importance);
        if (absValue > maxImp) maxImp = absValue;
        if (absValue < minImp) minImp = absValue;
      });

      // Update state with max and min importance
      setMaxImportance(maxImp);
      setMinImportance(minImp);

      featurePlot.forEach((show: boolean, index: number) => {
        if (!props.isChat) {
          show = true;
        }
        if (show && props.data?.featureNames !== undefined) {
          const absoluteImportanceValue = Math.abs(
            props.data.featureImportance[index]
          );
          const column = getCorrelationType(absoluteImportanceValue);
          const opacity = calculateOpacity(
            absoluteImportanceValue,
            minImportance,
            maxImportance
          );
          newData.push({
            key: props.data.featureNames[index],
            [column]: props.data.featureImportance[index],
            opacity
          });
        }
      });
      setData(newData);
    }
  }, [props.data]);

  useEffect(() => {
    if (divID === undefined && props?.chartID !== undefined) {
      setDivID(props?.chartID);
    }
  }, [props.chartID, divID]);

  useEffect(() => {
    if (props?.data !== undefined && props.data?.title !== undefined) {
      let splitTitle = props.data.title.split('\n');
      if (
        splitTitle !== undefined &&
        Array.isArray(splitTitle) &&
        splitTitle.length > 0
      ) {
        splitTitle = splitTitle.map((title) => title.trim());
        setTitles(splitTitle);
      }
    }
  }, [props.data]);

  useLayoutEffect(() => {
    if (divID !== undefined) {
      am5.array.each(am5.registry.rootElements, function (root) {
        if (root !== undefined && root.dom.id === props.chartID) {
          root.dispose();
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const root: any = am5.Root.new(divID, {
        tooltipContainerBounds: {
          top: 0,
          right: 0,
          bottom: 0,
          left: -100
        }
      });
      root.setThemes([am5themes_Animated.new(root)]);

      const chartContainer = root.container.children.push(
        am5.Container.new(root, {
          layout: root.verticalLayout,
          width: am5.percent(100),
          height: am5.percent(100)
        })
      );

      const chart = chartContainer.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          pinchZoomY: false,
          pinchZoomX: false
        })
      );

      const yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 0
      });
      yRenderer.grid.template.set('visible', false);

      const yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'key',
          renderer: yRenderer,
          paddingRight: 15,
          autoGridCount: false,
          equalSpacing: true,
          gridCount: 10,
          labelRotation: 90,
          axisHeight: 10,
          paddingTop: 10
        })
      );

      yAxis.children.unshift(
        am5.Label.new(root, {
          text: props?.data?.xlabel ?? 'Factors',
          textAlign: 'left',
          y: -25,
          width: am5.percent(100),
          x: am5.percent(50),
          fontWeight: '400',
          fontFamily: 'ft-system-bold',
          marginBottom: 50,
          paddingTop: 10
        })
      );

      const xRenderer = am5xy.AxisRendererX.new(root, {
        opposite: true
      });

      xRenderer.grid.template.set('strokeDasharray', [3]);

      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xRenderer,
          paddingBottom: 5,
          min: -1,
          max: 1
        })
      );

      xAxis.children.unshift(
        am5.Label.new(root, {
          text: props?.data?.ylabel ?? 'Impact',
          textAlign: 'left',
          width: am5.percent(10),
          x: -5,
          fontWeight: '400',
          fontFamily: 'ft-system-bold'
        })
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [yAxis, xAxis].forEach((axis: any) => {
        axis.get('renderer').labels.template.setAll({
          oversizedBehavior: 'truncate',
          fontSize: '12px',
          fill: am5.color(0x344054),
          fontWeight: '400',
          fontFamily: 'ft-system-regular',
          maxWidth: 150
        });
      });

      if (!props.isChat) {
        yAxis.get('renderer').labels.template.setAll({
          centerX: am5.p0
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const weakTooltip: any = am5.Tooltip.new(root, {
        dy: -5,
        pointerOrientation: 'vertical',
        getFillFromSprite: false,
        labelText: '{key}: [bold]{valueX}[/]',
        autoTextColor: false
      });

      weakTooltip.get('background').setAll({
        fill: colors.tooltipBackground,
        fillOpacity: 1
      });

      weakTooltip.label.setAll({
        fill: colors.tooltipText,
        textAlign: 'middle'
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const weakSeries: any = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Weak',
          xAxis,
          yAxis,
          valueXField: 'weak',
          categoryYField: 'key',
          sequencedInterpolation: true,
          calculateAggregates: true,
          maskBullets: false,
          tooltip: weakTooltip,
          legendLaberText: 'Weak',
          clustered: false,
          fill: colors.weak,
          paddingTop: 10
        })
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const moderateTooltip: any = am5.Tooltip.new(root, {
        dy: -5,
        pointerOrientation: 'vertical',
        getFillFromSprite: false,
        labelText: '{key}: [bold]{valueX}[/]',
        autoTextColor: false
      });

      moderateTooltip.get('background').setAll({
        fill: colors.tooltipBackground,
        fillOpacity: 1
      });

      moderateTooltip.label.setAll({
        fill: colors.tooltipText,
        textAlign: 'middle'
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const moderateSeries: any = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Mild',
          xAxis,
          yAxis,
          valueXField: 'moderate',
          categoryYField: 'key',
          sequencedInterpolation: true,
          calculateAggregates: true,
          maskBullets: false,
          tooltip: moderateTooltip,
          legendLaberText: 'Mild',
          clustered: false,
          fill: colors.moderate,
          paddingTop: 10
        })
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const strongTooltip: any = am5.Tooltip.new(root, {
        dy: -5,
        getFillFromSprite: false,
        labelText: '{key}: [bold]{valueX}[/]',
        autoTextColor: false,
        keepTargetHover: true
      });

      strongTooltip.get('background').setAll({
        fill: colors.tooltipBackground,
        fillOpacity: 1
      });

      strongTooltip.label.setAll({
        fill: colors.tooltipText,
        textAlign: 'middle'
      });

      const strongSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Strong',
          xAxis,
          yAxis,
          valueXField: 'strong',
          categoryYField: 'key',
          sequencedInterpolation: true,
          calculateAggregates: true,
          maskBullets: false,
          tooltip: strongTooltip,
          legendLaberText: 'Strong',
          clustered: false,
          fill: colors.strong,
          paddingTop: 10
        })
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [strongSeries, moderateSeries, weakSeries].forEach(
        (serie: am5xy.ColumnSeries) => {
          if (serie !== undefined) {
            // Modfify the opacity of each column of the series separately
            serie.columns.template.adapters.add(
              'fillOpacity',
              function (_fillOpacity, target: am5.RoundedRectangle) {
                const dataContext: FeatureImportanceData = target.dataItem
                  ?.dataContext as FeatureImportanceData;
                return dataContext.opacity;
              }
            );

            serie.columns.template.setAll({
              strokeOpacity: 0,
              cornerRadiusBR: 20,
              cornerRadiusTR: 20,
              cornerRadiusBL: 20,
              cornerRadiusTL: 20,
              width: am5.percent(100)
            });

            if (!props.isChat) {
              serie.columns.template.setAll({
                cornerRadiusBL: 0,
                cornerRadiusTL: 0,
                height: 24
              });
              const cellSize = 35;
              serie.events.on('datavalidated', function (ev) {
                const series = ev.target;
                const chart = serie.chart;
                if (chart === undefined) return;
                const xAxis = chart.xAxes.getIndex(0);
                if (xAxis === undefined) return;
                // Calculate how we need to adjust chart height
                const chartHeight =
                  series.data.length * cellSize +
                  xAxis.height() +
                  chart.get('paddingTop', 0) +
                  chart.get('paddingBottom', 0);

                // Set it on chart's container
                chart.root.dom.style.height = `${chartHeight}px`;
              });
            }
          }
        }
      );

      // feature_plot is an array of booleans that indicates which features are being plotted
      const featurePlot = props.data?.featurePlot as boolean[];
      const newData: FeatureImportanceData[] = [];
      featurePlot.forEach((show: boolean, index: number) => {
        if (!props.isChat) {
          show = true;
        }
        if (show && props.data?.featureNames !== undefined) {
          const absoluteImportanceValue = Math.abs(
            props.data.featureImportance[index]
          );
          const column = getCorrelationType(absoluteImportanceValue);
          const opacity = calculateOpacity(
            absoluteImportanceValue,
            minImportance,
            maxImportance
          );
          newData.unshift({
            key: props.data.featureNames[index],
            [column]: props.data.featureImportance[index],
            opacity
          });
        }
      });

      if (strongSeries?.data !== undefined) {
        strongSeries.data.setAll(newData);
      }
      if (moderateSeries.data !== undefined) {
        moderateSeries.data.setAll(newData);
      }
      if (weakSeries.data !== undefined) {
        weakSeries.data.setAll(newData);
      }
      yAxis.data.setAll(newData);

      const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
      cursor.lineX.set('visible', false);
      cursor.lineY.set('visible', false);

      if (strongSeries !== undefined) {
        strongSeries.appear();
      }
      if (moderateSeries !== undefined) {
        moderateSeries.appear();
      }
      if (weakSeries !== undefined) {
        weakSeries.appear();
      }

      chart.appear(1000, 100);

      if (props.isChat) {
        const legendContainer = chartContainer.children.push(
          am5.Container.new(root, {
            layout: root.verticalLayout,
            width: am5.percent(93),
            x: -20,
            paddingBottom: 5
          })
        );

        legendContainer.children.push(
          am5.Line.new(root, {
            stroke: am5.color(0xcccccc),
            strokeWidth: 1,
            width: am5.percent(100),
            x: am5.percent(10)
          })
        );

        const legendInnerContainer = legendContainer.children.push(
          am5.Container.new(root, {
            layout: root.horizontalLayout,
            width: am5.percent(100),
            paddingTop: 10,
            centerY: am5.percent(50)
          })
        );

        legendInnerContainer.children.push(
          am5.Label.new(root, {
            text: 'Correlation:',
            fontSize: 12,
            fontWeight: '400',
            fontFamily: 'ft-system-bold',
            x: am5.percent(10),
            centerY: am5.percent(50)
          })
        );

        const legend = legendInnerContainer.children.push(
          am5.Legend.new(root, {
            paddingBottom: 0,
            x: am5.percent(30),
            layout: root.horizontalLayout,
            centerY: am5.percent(50)
          })
        );

        legend.markerRectangles.template.setAll({
          cornerRadiusTL: 10,
          cornerRadiusTR: 10,
          cornerRadiusBL: 10,
          cornerRadiusBR: 10
        });

        legend.labels.template.setAll({
          fontSize: 10,
          fontWeight: '400',
          fontFamily: 'ft-system-regular'
        });

        legendInnerContainer.children.push(
          am5.Picture.new(root, {
            y: am5.percent(0),
            x: am5.percent(98),
            src: AIZWEILOGO,
            width: 50,
            position: 'absolute',
            centerY: am5.percent(50)
          })
        );

        legend.data.pushAll([strongSeries, moderateSeries, weakSeries]);
      }

      return () => {
        root._logo.dispose();
        root.dispose();
      };
    }
  }, [divID, data, maxImportance, minImportance]);

  return (
    <div id="custom-chart" className="custom-chart">
      {titles !== undefined && titles.length > 0 && (
        <div id="custom-chart-div-title" className="custom-chart-div-title">
          <div id="custom-chart-title" className="custom-chart-title">
            {titles.map((title: string, index: number) => (
              <div
                id="custom-chart-title-1"
                className="custom-chart-title-1"
                key={`title-chart-${index}`}
              >
                {title}
              </div>
            ))}
          </div>
          <img
            src={RECTANGLEGRADIENT}
            alt={'rectangle-gradient'}
            className="rectangle-gradient"
          />
        </div>
      )}
      {divID !== undefined && (
        <div
          id={divID}
          className="chart"
          style={{ height: data.length <= 13 ? '400px' : '800px' }}
        />
      )}
    </div>
  );
};

export default Chart;
