import { useEffect, useState, type ReactElement } from 'react';

import Chat from 'chat/Chat';
import NavBar from 'common/Navbar';
import PrivacyNav from 'common/PrivacyNav';
import ModalController from 'common/modal/ModalController';

import { modalControllerAtom } from 'atoms/atomModalController';
import { magicMessageDerived } from 'chat/atoms/atomMagicMessage';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';
import { AssistantApiSocketClient } from 'services/AssistantApiSocketClient';
import { AuthenticationService } from 'services/authentication/AuthenticationService';

import { Portal } from '@mui/base';
import { Alert, Snackbar } from '@mui/material';
import { notifyMessageAtom } from 'atoms/atomMessageError';
import { decodeJwt } from 'jose';
import { Cookie } from 'utils/cookie';
import { MODAL_TYPES } from 'common/interfaces/enums';
import { chatActiveAtom } from './atoms/AtomChatActive';

const Home = (): ReactElement => {
  const assistantApiSocketClient = AssistantApiSocketClient.getInstance();
  const authenticationService = AuthenticationService.getInstance();

  const [chatActive, setChatActive] = useRecoilState(chatActiveAtom);
  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);
  const resetAtomModalController = useResetRecoilState(modalControllerAtom);
  const magicMessage = useSetRecoilState(magicMessageDerived);

  const snackBar = useRecoilValue(notifyMessageAtom);
  const resetNotifyMessage = useResetRecoilState(notifyMessageAtom);

  const [validToken, setValidToken] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('token') && searchParams.has('mode')) {
      setLoading(false);
      setModalController({ type: MODAL_TYPES.RESET_PASSWORD });
    } else {
      void validateToken();
    }
    if (!chatActive) {
      localStorage.removeItem('lastMessageStorage');
    }
  }, [chatActive, validToken]);

  async function validateToken(): Promise<void> {
    const token = Cookie.get('token');

    if (token !== undefined && !validToken) {
      const { exp } = decodeJwt(token);

      if (exp !== undefined && exp * 1000 < Date.now()) {
        Cookie.remove('token');
        setLoading(false);
      } else {
        await authServiceTokenValidation(token);
      }
    } else {
      if (!chatActive && modalController.type === MODAL_TYPES.CLOSE) {
        setModalController({ type: MODAL_TYPES.START_CHAT });
      }
      setLoading(false);
    }
  }

  async function authServiceTokenValidation(token: string): Promise<void> {
    setLoading(true);
    await authenticationService
      .authenticateByToken(token)
      .then(() => {
        assistantApiSocketClient.setAuth(token);
        authenticationService.setAuthToken(token);
        setValidToken(true);
        if (!chatActive) {
          setChatActive(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setValidToken(false);
      });
  }

  return (
    <>
      <NavBar
        disabled={modalController.type === MODAL_TYPES.START_CHAT}
        user={authenticationService.userData}
        logged={chatActive}
        onChange={() => {
          if (chatActive || modalController.type !== MODAL_TYPES.START_CHAT) {
            magicMessage(undefined);
            Cookie.remove('token');
            setModalController({ type: MODAL_TYPES.START_CHAT });
            setChatActive(false);
            setValidToken(false);
            resetAtomModalController();
          }
        }}
      />
      <PrivacyNav />
      {!loading ? (
        <>
          {chatActive ? <Chat /> : null}
          <Portal>
            <Snackbar
              open={snackBar.open}
              autoHideDuration={10000}
              onClose={() => {
                resetNotifyMessage();
              }}
            >
              <Alert severity={snackBar.status}>{snackBar.message}</Alert>
            </Snackbar>
          </Portal>
          <ModalController />
        </>
      ) : null}
    </>
  );
};

export default Home;
