import { type ReactElement } from 'react';

const PrivacyNav = (): ReactElement => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: '24px',
        right: '24px',
        width: 'auto'
      }}
    >
      <a
        target="_blank"
        rel="noreferrer"
        style={{
          fontSize: '14px',
          color: '#344054', // $base-80
          textDecoration: 'none',
          letterSpacing: '-0.02em',
          userSelect: 'none'
        }}
        href="https://aizwei.com/privacy"
      >
        Privacy policy
      </a>
    </div>
  );
};

export default PrivacyNav;
