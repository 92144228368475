import { Box, Chip, Tooltip, Typography } from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import { ICON_TYPE } from 'common/interfaces/enums';
import type { DatasetSchema, SampleData } from 'common/interfaces/interfaces';
import { transformFeatureStateAtom } from 'featureEngineering/atoms/atomTransformFeature';
import { type ModifiedVariables } from 'featureEngineering/featureEngineeringInterface';
import { getPreviewModified } from 'featureEngineering/utils/transformationUtils';
import { FeatureType } from 'playground/interfaces/playground';
import { type ReactElement, memo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import 'common/Common.scss';
import './previewDataset.scss';

const CustomTooltip = (
  <Tooltip
    title={
      <Box className="tooltip" sx={{ width: '175px', padding: '6px' }}>
        <p>There are 6 types for data:</p>
        <p>
          -<u>ID</u> : unique values (required one ID column per dataset)
        </p>
        <p>
          -<u>Categorical</u> : set amount of values that can be categorised{' '}
        </p>
        <p>
          -<u>Binary</u> : categorical values with only two variables{' '}
        </p>
        <p>
          -<u>Numerical</u> : only numbers, cannot be categorise{' '}
        </p>
        <p>
          -<u>Textual</u> : text or text and numbers which cannot be categorise{' '}
        </p>
        <p>
          -<u>Date</u> : date values which could come in different formats
        </p>
      </Box>
    }
    placement="bottom"
    arrow
  >
    <div>
      <CustomIcon
        className="grey-icon-no-hover"
        style={{
          justifyContent: 'center',
          paddingLeft: '5px',
          width: '15px'
        }}
        type={ICON_TYPE.INFO_CIRCLE}
      />
    </div>
  </Tooltip>
);

interface PreviewDatasetProps {
  tableData: DatasetSchema[];
  options?: {
    title?: string;
    clickAction?: (name: string, index: number) => void;
    selectedRow?: number;
  };
}

const PreviewDataset = memo(function PreviewDataset({
  tableData,
  options = {}
}: PreviewDatasetProps) {
  const transformationState = useRecoilValue(transformFeatureStateAtom);
  const [listOpenContextuals, setListOpenContextuals] = useState<number[]>([]);

  const getPreviewClasses = (
    modifiedData: ModifiedVariables,
    index: number
  ): string => {
    let { disregard, edited, added } = modifiedData;

    disregard = Boolean(disregard);
    edited = Boolean(edited);
    added = Boolean(added);

    if (disregard && (Boolean(edited) || added)) {
      edited = false;
      added = false;
    } else if (edited) {
      added = false;
    }
    const isPreview = options.clickAction !== undefined ? 'preview-hover' : '';
    return `${options.selectedRow === index ? 'transform-selected' : isPreview}
      ${disregard ? 'disregard' : ''}
      ${edited ? 'preview-edited' : ''}
      ${added ? 'preview-added' : ''}`;
  };

  const tdCell = (
    tdData: SampleData,
    index: number,
    options: { withChip: boolean; context?: string } = {
      withChip: true,
      context: undefined
    }
  ): ReactElement | null => {
    const { withChip, context } = options;
    if (tdData !== undefined) {
      const isOpen = listOpenContextuals.includes(index);
      let condition = Array.isArray(tdData) && tdData.length;
      let tdChild: SampleData | JSX.Element[] | ReactElement | [] = tdData;

      if (Array.isArray(tdData) && typeof condition === 'number') {
        if (tdData.length > 3) {
          const newtdChild: JSX.Element[] = [];
          if (tdData.length > 3) {
            if (isOpen) {
              condition = tdData.length;
            } else {
              condition = 3;
            }
          }

          for (let i = 0; i < condition; i++) {
            const data = `${tdData[i] as string} `;
            newtdChild.push(
              <Chip className="chip" key={`preview-cell-${i}`} label={data} />
            );
          }
          if (isOpen || condition !== tdData.length) {
            newtdChild.push(
              <Chip
                className="chip"
                key={`preview-cell-${condition}`}
                label={
                  isOpen ? (
                    <u>show less</u>
                  ) : (
                    <u>show more ({tdData.length - 3})</u>
                  )
                }
                onClick={(event) => {
                  event.stopPropagation();
                  if (isOpen) {
                    const newListOpenContextuals = listOpenContextuals.filter(
                      (item) => item !== index
                    );
                    setListOpenContextuals(newListOpenContextuals);
                  } else {
                    setListOpenContextuals([...listOpenContextuals, index]);
                  }
                }}
              />
            );
          }
          tdChild = newtdChild;
        } else {
          tdChild = tdData.map((data, index) => {
            return (
              <Chip
                className="chip"
                key={`preview-cell-${index}`}
                label={`${data as string}`}
              />
            );
          });
        }
      } else if (withChip) {
        tdChild = <Chip className="chip" label={tdData as string} />;
      }

      const isLongWord = typeof tdData === 'string' && !tdData.includes(' ');

      return (
        <td>
          <Box
            className={`${withChip ? 'preview-flex' : 'first-column'} ${
              isOpen || condition === 3 ? 'previewCategoricalOpen' : ''
            } `}
            style={
              isLongWord
                ? { wordBreak: 'break-all' }
                : { wordBreak: 'keep-all' }
            }
          >
            {tdChild as ReactElement}
            {context !== undefined && (
              <Typography className="preview-context">{context}</Typography>
            )}
          </Box>
        </td>
      );
    }
    return null;
  };

  return (
    <Box style={{ display: 'contents' }}>
      {options.title !== undefined ? (
        <h1 className="table-label">{options.title}</h1>
      ) : null}
      <div className="wrapp-preview-table">
        <table className="preview-table">
          <thead className="preview-table-header">
            <tr className="preview-table-row">
              <th style={{ borderRadius: '10px 0px 0px 0px' }}>Column</th>
              <th>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  Type {CustomTooltip}
                </Box>
              </th>
              <th>Sample data</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, index) => {
              const renderModifyedVariables = getPreviewModified(
                transformationState,
                data.name
              );
              let context: string | undefined;
              let sampleData = data.sampleData;
              if (
                data.dataValue === FeatureType.NUMERICAL ||
                data.dataValue === FeatureType.DATETIME
              ) {
                context = 'min_max';
                if (
                  typeof data.sampleData === 'object' &&
                  'min' in data.sampleData &&
                  'max' in data.sampleData
                ) {
                  sampleData = `${data.sampleData.min}-${data.sampleData.max}`;
                }
              }
              return (
                <tr
                  className={`preview-table-row ft-sm ${getPreviewClasses(
                    renderModifyedVariables,
                    index
                  )}`}
                  key={`preview-data-${index}`}
                  onClick={() => {
                    if (
                      options.selectedRow !== index &&
                      options.clickAction !== undefined
                    ) {
                      options.clickAction(data.name, index);
                    }
                  }}
                >
                  {tdCell(data.name, index, { withChip: false })}
                  {tdCell(data.dataValue, index)}
                  {tdCell(sampleData as SampleData, index, {
                    withChip: true,
                    context
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Box>
  );
});

export default PreviewDataset;
