import type {
  CSSInterpolation,
  ComponentsOverrides,
  ComponentsProps,
  Theme
} from '@mui/material';

import {
  BASE_COLORS,
  hexWithTransparencyPercentage
} from 'themes/foundations/Colors';
import { RADIUS } from 'themes/foundations/Radius';
import {
  dialogComposedContent,
  dialogFullContent
} from './dialogComponents/dialogContent';
import {
  dialogCompsedHeader,
  dialogFullHeader
} from './dialogComponents/dialogHeader';
import {
  dialogComposedFooter,
  dialogSmFooter
} from './dialogComponents/dialogsFooter';
import {
  heightResponsive,
  widthResposive
} from './dialogComponents/responsive';

const dialogRoot: CSSInterpolation = {
  '.playground-modal': {
    '& .individual-body': {
      minHeight: '70vh!important',
      minWidth: '80vw!important'
    }
  },
  '& .MuiDialog-paper': {
    borderRadius: RADIUS.MEDIUM,
    boxShadow:
      '0px 4px 20px ' + hexWithTransparencyPercentage(BASE_COLORS.BASE_90, 25),
    color: BASE_COLORS.BASE_90,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '1024px',
    userSelect: 'none',
    overflow: 'hidden',
    ...heightResponsive,
    ...widthResposive
  },
  '& .dialog-sm': {
    display: 'block',
    width: '400px',
    background: 'white',
    padding: '24px',
    borderRadius: RADIUS.MEDIUM_LARGE,
    '& .dialog-footer': dialogSmFooter
  },
  '& .dialog-md': {
    width: '498px',
    padding: '30px 24px 24px',
    borderRadius: RADIUS.XXLARGE
  },
  '& .dialog-full': {
    width: '100%',
    padding: '30px 24px 24px',
    borderRadius: RADIUS.MEDIUM,
    '& .dialog-header': dialogFullHeader,
    '& .dialog-content': dialogFullContent,
    ...heightResponsive,
    ...widthResposive
  },
  '& .dialog-composed': {
    width: '100%',
    display: 'grid',
    gridTemplateRows: '[header] 60px [body] auto [footer] 80px !important',
    '& .dialog-header': dialogCompsedHeader,
    '& .dialog-content': dialogComposedContent,
    '& .dialog-footer': dialogComposedFooter,
    ...heightResponsive,
    ...widthResposive
  }
};

interface MUIDialogComponent {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
}

export const MuiDialogRegular: MUIDialogComponent = {
  styleOverrides: {
    root: dialogRoot
  },
  defaultProps: {
    fullWidth: true
  }
};
