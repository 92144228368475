import { Button } from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import { type ICON_TYPE } from 'common/interfaces/enums';
import type { CSSProperties, ReactElement, ReactNode, RefObject } from 'react';
import { BASE_FONT_WEIGHT } from 'themes/foundations/Typography';

interface IconProps {
  type: ICON_TYPE;
  position: 'left' | 'right';
  style?: CSSProperties;
}

interface CustomButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outlined';
  small?: boolean;
  children?: ReactNode;
  icon?: IconProps;
  font?: 'regular' | 'bold';
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  style?: CSSProperties;
  disabled?: boolean;
  dataCy?: string;
  focusRipple?: boolean;
  disableRipple?: boolean;
  customRef?: RefObject<HTMLButtonElement> | null;
  className?: string;
  customKey?: string;
  id?: string;
}

const CustomButton = ({
  variant = 'primary',
  small = false,
  children = undefined,
  icon = undefined,
  font = 'bold',
  onClick = undefined,
  style = undefined,
  disabled,
  dataCy,
  focusRipple = true,
  disableRipple = false,
  customRef = null,
  className,
  customKey,
  id
}: CustomButtonProps): ReactElement => {
  const generateIcon = (icon: IconProps): JSX.Element => {
    const positionMargin =
      icon.position === 'right' ? '0px 0px 0px 10px' : '0px 10px 0px 0px';
    return (
      <CustomIcon
        type={icon.type}
        style={{
          width: '20px',
          height: '20px',
          margin: children !== undefined ? positionMargin : '0px',
          ...{ ...icon?.style }
        }}
      />
    );
  };

  const getFontWeight = (font: string): number => {
    if (font === 'bold') {
      return BASE_FONT_WEIGHT.BOLD;
    }
    return BASE_FONT_WEIGHT.REGULAR;
  };

  return (
    <Button
      ref={customRef}
      key={customKey}
      variant={variant}
      id={id}
      className={className}
      style={{
        width: '100%',
        textTransform: 'none',
        maxWidth: 'max-content',
        minWidth: small ? 'none' : '',
        padding: small ? '6px 12px' : '12px 24px',
        fontWeight: getFontWeight(font),
        ...{ ...style }
      }}
      onClick={onClick}
      disabled={disabled}
      data-cy={dataCy}
      focusRipple={focusRipple}
      disableRipple={disableRipple}
    >
      {icon !== undefined && icon?.position === 'left' && generateIcon(icon)}
      {children}
      {icon !== undefined && icon?.position === 'right' && generateIcon(icon)}
    </Button>
  );
};

export default CustomButton;
