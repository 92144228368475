import { Box, Typography } from '@mui/material';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';
import { NUMBERS_STRINGS } from 'common/interfaces/enums';
import type { Row, TableData } from 'common/interfaces/interfaces';
import PreviewRows from 'featureEngineering/previewRows/PreviewRows';
import SwitchTransformer from 'mySpace/components/switchTransformer/SwitchTransformer';
import { useEffect, useRef, useState } from 'react';
import Filtering from '../filtering/Filtering';

import {
  createErrorNotification,
  notifyMessageAtom
} from 'atoms/atomMessageError';
import {
  previewfiltersStateAtom,
  type FilterOptions
} from 'bulkPredictions/atoms/atomPreviewfilersState';
import type { PlaygroundReturns } from 'bulkPredictions/bulkPredictions/BulkPredictions';
import {
  getColumnOptions,
  getResizeTableDataByfilters,
  updateFilterValue
} from 'bulkPredictions/utils/summaryLayerUtils';
import type { Filter } from 'models/Filter';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';
import { ExposeService } from 'services/ExposeService';

import type { Features } from 'chat/interfaces/messages';
import 'common/Common.scss';
import './SummaryLayer.scss';
import {
  USER_TRACKING_SUMMARY_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { GAUserEvent } from 'utils/utils';

export interface Asset {
  targetVariable?: string;
  modelName?: string;
  features?: Features;
  documentUrl?: string;
  description?: string;
}

interface SummaryLayerOptions {
  datasetIds?: { datasetId: string; originalDatasetId: string };
  asset?: Asset;
  tableData?: TableData;
  activeSwitch?: boolean;
  online?: boolean;
  options?: PlaygroundReturns;
  disableFirstSwitchButton?: boolean;
  disableSecondSwitchButton?: boolean;
  bulkPredictions?: boolean;
}

type getRowsOptions = [
  number,
  number,
  string | undefined,
  string | undefined,
  Filter | undefined
];

const SummaryLayer = ({
  datasetIds,
  asset,
  tableData,
  options,
  activeSwitch = true,
  online = false,
  disableFirstSwitchButton = false,
  disableSecondSwitchButton = false,
  bulkPredictions = false
}: SummaryLayerOptions): JSX.Element => {
  const { playgroundReturnData, selectedRowData } = options ?? {};
  const [filterOptions, setFilterOptions] = useRecoilState(
    previewfiltersStateAtom
  );
  const resetFilters = useResetRecoilState(previewfiltersStateAtom);
  const [reRenderFilters, setReRenderFilters] = useState<number>(0);
  const setNotifyMessage = useSetRecoilState(notifyMessageAtom);
  const userLocationVariable = useRecoilValue(userTrackingLocation);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [actualTableData, setActualTableData] = useState<TableData | undefined>(
    tableData
  );
  const initialSelection =
    selectedRowData !== undefined
      ? Object.values(selectedRowData).toString()
      : '';
  const [selectedSummary, setSelectedSummary] =
    useState<string>(initialSelection);
  const exposeService = ExposeService.getInstance();
  const updateFilterOptionsRef = useRef<string>();
  const { datasetId, originalDatasetId } = datasetIds ?? {};

  const [detailPreview, setDetailPreview] = useState<string>(
    NUMBERS_STRINGS.SECOND
  );
  const initialResults = tableData?.rows.length ?? 0;
  const [results, setResults] = useState<number>(initialResults);

  const isValidNumberSecond =
    detailPreview === NUMBERS_STRINGS.SECOND && actualTableData !== undefined;

  useEffect(() => {
    const filters = JSON.stringify(filterOptions);
    const { current: updateFilterOptions } = updateFilterOptionsRef;
    if (filters !== updateFilterOptions) {
      if (datasetId !== undefined && online && !previewLoading) {
        getTableDataRows(filterOptions);
      }
      updateFilterOptionsRef.current = filters;
    }
  }, [filterOptions]);

  useEffect(() => {
    if (isValidNumberSecond && previewLoading) {
      setPreviewLoading(false);
    } else if (previewLoading) {
      setPreviewLoading(false);
    }
  }, [detailPreview, previewLoading]);

  useEffect(() => {
    if (reRenderFilters === 1) {
      setReRenderFilters(0);
      setActualTableData(tableData);
    }
  }, [reRenderFilters]);

  const getTableDataRows = (
    filterOptions: FilterOptions,
    forceUpdate?: boolean
  ): void => {
    const { filter, page, pageSize: size, sortBy, order } = filterOptions;
    const { current: updateFilterOptions } = updateFilterOptionsRef;

    const { isPageUpdate, isFilterUpdate } = getResizeTableDataByfilters(
      filterOptions,
      updateFilterOptions
    );

    // On regular datasets, the originalDatasetId is the same as the datasetId
    // On transformed datasets, the originalDatasetId is the id of the original dataset
    // and the datasetId is the id of the transformed dataset (view)
    if (datasetId !== undefined) {
      let newTableData: TableData = {
        rows: [],
        keys: actualTableData?.keys,
        collectionSize: 0
      };

      const isView = originalDatasetId !== datasetId;
      const options: getRowsOptions = [page, size, sortBy, order, filter];

      const previewResponse =
        isView && originalDatasetId !== undefined
          ? exposeService.getViewRows(originalDatasetId, datasetId, ...options)
          : exposeService.getDatasetRows(datasetId, ...options);

      previewResponse
        .then((data: TableData | undefined): void => {
          // If no data is fetched, set table data with empty rows and collection size 0
          if (data?.rows !== undefined) {
            // Initialize newRows based on existing tableData if available, else fill with undefined
            let newRows: Array<Row | undefined> =
              actualTableData === undefined
                ? new Array(data.collectionSize).fill(undefined)
                : [...actualTableData.rows];

            // Remove all data from table when the user requests sorting or filtering
            // This is to ensure that the table data doesn't store values without the new sorting or filtering
            if (filterOptions?.reset === true) {
              const newFilter: { filter?: Filter | undefined } = {
                filter: updateFilterValue(filterOptions.filter)
              };
              const newOptions = {
                ...filterOptions,
                ...(newFilter.filter !== undefined ? newFilter : {}),
                reset: false
              };
              setFilterOptions(newOptions);
              newRows = new Array(data.collectionSize).fill(undefined);
            }

            // Calculate range for pagination
            const startItem = (page - 1) * size;
            const endItem = startItem + data.rows.length;

            // Assign rows from fetched data to newRows at appropriate indices
            for (let index = startItem; index < endItem; index++) {
              newRows[index] = data.rows[index - startItem];
            }

            // Update table data with new rows
            newTableData = {
              rows: newRows,
              keys: data?.keys,
              collectionSize: data?.collectionSize
            };

            setActualTableData({ ...newTableData });

            if (forceUpdate === true || isPageUpdate || isFilterUpdate) {
              setPreviewLoading(true);
            }
            if (
              (forceUpdate === true || isFilterUpdate) &&
              !isPageUpdate &&
              results !== newTableData.rows.length
            ) {
              setResults(newTableData.rows.length);
            }
          }
        })
        .catch((): void => {
          createErrorNotification(
            'Something went wrong while loading the data',
            setNotifyMessage
          );
        });
    }
  };

  const handleClickedRow = (name: string, index: number | string): void => {
    if (typeof index === 'string') {
      GAUserEvent(
        `${userLocationVariable.current}_${USER_TRACKING_SUMMARY_ACTIONS.SELECT_ROW}`
      );
      setSelectedSummary(index);
    }
  };

  const handleResetFilter = (): void => {
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_SUMMARY_ACTIONS.RESET_FILTERS}`
    );
    resetFilters();
    setReRenderFilters(1);
  };

  const buildTableRender = (actualTableData: TableData): JSX.Element => {
    return (
      <Box
        className="summary-layer-preview-block"
        style={{
          display: isValidNumberSecond ? 'contents' : 'flex'
        }}
      >
        {isValidNumberSecond && !previewLoading ? (
          <PreviewRows
            tableData={actualTableData}
            updateFilterOptions={updateFilterOptionsRef.current}
            getTableDataRows={getTableDataRows}
            previewLoadingController={[previewLoading, setPreviewLoading]}
            options={{
              pagination: true,
              resultsController: [results, setResults],
              predictVariable: asset?.targetVariable,
              modelVariable: asset?.modelName,
              summarySelected: selectedSummary,
              bulkPredictionsData: bulkPredictions ? asset : {},
              playgroundReturnData,
              clickAction: handleClickedRow
            }}
            online={online}
          />
        ) : (
          previewLoading && <LoadingDots />
        )}
      </Box>
    );
  };

  return (
    <>
      {actualTableData === undefined ? (
        <></>
      ) : (
        <Box className="summary-layer-body-container">
          <Box className="summary-layer-filtering">
            {reRenderFilters === 0 && (
              <Filtering
                targetVariable={asset?.targetVariable}
                paginationSizeValue={filterOptions.pageSize ?? 10}
                columnOptions={
                  actualTableData?.keys !== undefined
                    ? getColumnOptions(actualTableData, asset)
                    : []
                }
                handleResetFilter={handleResetFilter}
                rowsSize={results}
                online={online}
              />
            )}
          </Box>
          <Box className="summary-layer-table">
            <Box className="summary-layer-switch-head">
              <Typography className="summary-layer-switch-head-title color-dark">
                Total results: {results}
              </Typography>
              {activeSwitch && (
                <SwitchTransformer
                  detailPreview={detailPreview}
                  setDetailPreview={setDetailPreview}
                  disableFirstButton={disableFirstSwitchButton}
                  disableSecondButton={disableSecondSwitchButton}
                />
              )}
            </Box>
            <Box className="summary-layer-block">
              {buildTableRender(actualTableData)}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SummaryLayer;
