import type { ReactElement } from 'react';
import { Box } from '@mui/material';
import type { NotifyBoxTypes } from 'common/interfaces/interfaces';
import {
  ErrorOutlineOutlined,
  CheckCircleOutlined,
  InfoOutlined
} from '@mui/icons-material';
import './NotifyBox.scss';

const NotifyBox = ({
  type,
  message,
  children
}: NotifyBoxTypes): ReactElement => {
  let AlertBoxType = { icon: <InfoOutlined />, class: 'info' };
  if (type === 'error') {
    AlertBoxType = { icon: <ErrorOutlineOutlined />, class: 'error' };
  }
  if (type === 'success') {
    AlertBoxType = { icon: <CheckCircleOutlined />, class: 'success' };
  }

  return (
    <Box className={'alertBox ' + AlertBoxType.class}>
      <Box sx={{ width: 'fit-content' }}>{AlertBoxType.icon}</Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {message !== undefined && <Box>{message}</Box>}
        {children !== undefined && <Box>{children}</Box>}
      </Box>
    </Box>
  );
};

export default NotifyBox;
