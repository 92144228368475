import type {
  ComponentType,
  Dispatch,
  MutableRefObject,
  ReactElement,
  SetStateAction
} from 'react';

import {
  BOT_COMPONENT,
  BOT_MESSAGE,
  COMPONENT_TYPE,
  TYPES,
  USER_MESSAGE
} from 'chat/interfaces/enums';

import type {
  Blocks,
  CustomMessage,
  LabelPayloadMessage,
  MessageFormatOptionalsProps,
  MessageFormatProps,
  PayloadMessage,
  PayloadParserMessage,
  PayloadSubtypeTypes,
  TextPayloadMessage,
  TransformTextMessageLink
} from 'chat/interfaces/messages';

import type {
  IntroduceDatasetTypes,
  IntroduceModelTypes,
  ModalControllerModalType,
  RegularComponentTypes,
  RegularProps
} from 'common/interfaces/interfaces';

import targetIcon from 'assets/imgs/target.png';
import Chart from 'chat/components/chart/Chart';
import { MODAL_TYPES } from 'common/interfaces/enums';
import { includeURL, transformDataToPlot } from 'utils/utils';
import { v4 as uuidv4 } from 'uuid';
import {
  USER_TRACKING_LOCATION_NAMES,
  type UserTrackingLocation
} from 'atoms/atomUserLocation';

interface PayloadStateController {
  messagesProcessed: MutableRefObject<number[]>;
  lastMessageIsCheckBox: MutableRefObject<boolean>;
  setModalController: Dispatch<SetStateAction<ModalControllerModalType>>;
  userLocationController: [
    UserTrackingLocation,
    Dispatch<SetStateAction<UserTrackingLocation>>
  ];
}

interface PayloadBocks {
  blocks: Blocks['blocks'];
  index?: number;
}

const uploadTextRegex = /\supload\s/gm;

const linkIdentifyMetaData = [
  'link:',
  'https://www.aizwei.com/preparing-your-data'
];

const COMPONENTS_CLASSES: Record<CustomMessage['from'], string | undefined> = {
  [BOT_MESSAGE.MESSAGE]: 'bot-message',
  [BOT_MESSAGE.MESSAGE_2]: 'bot-message2',
  [BOT_MESSAGE.WHITE]: 'bot-message-white',
  [BOT_MESSAGE.CHART]: 'bot-message-chart',
  [BOT_MESSAGE.COMPONENT]: 'bot-message-component',
  [USER_MESSAGE.MESSAGE]: 'user-message',
  [USER_MESSAGE.FILE]: 'user-message2',
  [BOT_MESSAGE.FILE]: 'bot-message-file',
  [BOT_MESSAGE.LINK_PREVIEW]: 'bot-message-link-preview',
  [BOT_COMPONENT.PROGRESS_BAR]: 'bot-message-progress',
  [BOT_COMPONENT.IMAGE]: 'bot-message-image-component',
  [BOT_COMPONENT.COMPONENT]: undefined,
  [BOT_MESSAGE.SHOW_MESSAGE]: undefined,
  [BOT_MESSAGE.OPEN_MODAL]: 'bot-message-open-modal'
};

export const createMessageFormat = (
  { componentType, componentData }: MessageFormatProps,
  {
    fromData,
    visible,
    style,
    aditionalMessageData
  }: MessageFormatOptionalsProps
): CustomMessage => {
  let formatedMessage = {
    id: uuidv4(),
    visible: visible ?? true,
    from: fromData,
    [componentType]: componentData
  };

  if (style !== undefined) {
    formatedMessage.style = style;
  }
  if (aditionalMessageData !== undefined) {
    formatedMessage = { ...formatedMessage, ...aditionalMessageData };
  }
  return formatedMessage;
};

const transformLinkMetaData = (
  messagesProcessed: MutableRefObject<number[]>,
  link: boolean,
  textMessage: string | ReactElement,
  fromData: CustomMessage['from']
): TransformTextMessageLink => {
  if (
    typeof textMessage === 'string' &&
    textMessage.match(uploadTextRegex) !== null &&
    !textMessage.includes('matching')
  ) {
    link = messageContainsLinkWord(textMessage);
    if (!link) {
      const textPayload = textMessage.toLowerCase();
      link = textPayload.includes('data') && textPayload.includes('upload');
    }
  } else if (
    typeof textMessage === 'string' &&
    textMessage.includes('wrong') &&
    textMessage.includes('link')
  ) {
    link = true;
  } else if (
    typeof textMessage === 'string' &&
    textMessage.startsWith('Thank you! To sum up, your goal')
  ) {
    const textMessageSplit: string = textMessage.split('your')[1];
    const textGoal = `Your ${textMessageSplit}`;
    textMessage = (
      <div key={uuidv4()}>
        <img src={targetIcon} alt={'Target'} key={uuidv4()} />
        <span key={uuidv4()}>{textGoal}</span>
      </div>
    );
    fromData = BOT_MESSAGE.WHITE;
  } else if (
    typeof textMessage === 'string' &&
    textMessage.startsWith("{'title") &&
    textMessage.endsWith('}') &&
    textMessage.includes('xlabel') &&
    textMessage.includes('ylabel')
  ) {
    const formattedData = textMessage
      .replaceAll("'", '"')
      .replaceAll('True', 'true')
      .replaceAll('False', 'false');
    const data = JSON.parse(formattedData);
    const plot = transformDataToPlot(data);
    textMessage = (
      <Chart
        data={plot}
        chartID={`custom-chart-div-${messagesProcessed.current.length}`}
        isChat={true}
      />
    );
    fromData = BOT_MESSAGE.CHART;
  }
  return { textMessage, fromData, link };
};

const getPayloadText = (
  payloadMessage?: string | TextPayloadMessage | PayloadParserMessage
): string => {
  if (typeof payloadMessage === 'string') {
    return payloadMessage;
  }
  return getPayloadText(payloadMessage?.text);
};

const generatePayloadSubtype = (
  componentSubtype: PayloadSubtypeTypes,
  lastMessageIsCheckBox: MutableRefObject<boolean>,
  overrideAlternativeData?: LabelPayloadMessage
): PayloadSubtypeTypes => {
  const setTitle = (
    compSubtype: {
      placeholder?: { text: string };
      text?: string | { text: string };
    },
    overrideTitle?: LabelPayloadMessage
  ): string => {
    let finalText = '';
    if (
      overrideTitle?.title !== undefined ||
      overrideTitle?.text !== undefined
    ) {
      finalText = overrideTitle.title ?? getPayloadText(overrideTitle.text);
    }
    if (finalText === '') {
      if (compSubtype.placeholder?.text !== undefined) {
        finalText = compSubtype.placeholder?.text;
      } else if (compSubtype.text !== undefined) {
        finalText = getPayloadText(compSubtype.text);
      }
    }
    return finalText;
  };
  let formatSubtype = componentSubtype;
  componentSubtype.title = setTitle(componentSubtype, overrideAlternativeData);
  componentSubtype.disable = false;
  switch (componentSubtype.type.toUpperCase()) {
    case BOT_COMPONENT.PLAIN_TEXT:
      componentSubtype.type = BOT_COMPONENT.PLAIN_TEXT;
      componentSubtype.text = getPayloadText(componentSubtype.text);
      break;
    case BOT_COMPONENT.BUTTON:
      componentSubtype.buttons = [getPayloadText(componentSubtype.text)];
      componentSubtype.type = lastMessageIsCheckBox.current
        ? BOT_COMPONENT.CHECKBOXES
        : BOT_COMPONENT.RESPONSE_BUTTONS;
      break;
    case BOT_COMPONENT.CHECKBOXES:
      lastMessageIsCheckBox.current = true;
      if (
        Array.isArray(componentSubtype.options) &&
        componentSubtype.options.length > 0
      ) {
        componentSubtype.options = componentSubtype.options.map((option) => ({
          text: getPayloadText(option.text),
          value: option.value
        }));
      }
      componentSubtype.title =
        componentSubtype.title ?? componentSubtype.label?.text;
      componentSubtype.type = BOT_COMPONENT.CHECKBOXES;
      break;
    case BOT_COMPONENT.RADIO_BUTTONS:
      if (
        Array.isArray(componentSubtype.options) &&
        componentSubtype.options.length > 0
      ) {
        let responseButtons = true;
        componentSubtype.buttons = componentSubtype.options.map((option) => {
          if (option.icon !== null && option.icon !== undefined) {
            responseButtons = false;
            return { value: option.value, icon: option.icon };
          }
          return getPayloadText(option.text);
        });
        componentSubtype.title =
          componentSubtype.title !== undefined
            ? componentSubtype.title !== ''
              ? componentSubtype.title
              : 'Select an option'
            : 'Select an option';
        componentSubtype.type = responseButtons
          ? BOT_COMPONENT.RESPONSE_BUTTONS
          : BOT_COMPONENT.RADIO_BUTTONS;
      }
      break;
    case BOT_COMPONENT.STATIC_SELECT:
      if (
        Array.isArray(componentSubtype.options) &&
        componentSubtype.options.length > 0
      ) {
        componentSubtype.options = componentSubtype.options.map((option) => {
          const component = {
            componentType: COMPONENT_TYPE.MESSAGE,
            componentData: getPayloadText(option.text)
          };
          const from = {
            aditionalMessageData: {
              label: getPayloadText(option.text),
              value: option.value
            }
          };
          return createMessageFormat(component, from);
        });
      }
      componentSubtype.type = BOT_COMPONENT.SELECT;
      break;
    case BOT_COMPONENT.PLAIN_TEXT_INPUT:
      if (componentSubtype.element?.placeholder?.text !== undefined) {
        componentSubtype.title = componentSubtype.element.placeholder.text;
      } else if (componentSubtype.label?.text !== undefined) {
        componentSubtype.title = componentSubtype.label.text;
      }
      componentSubtype.type = BOT_COMPONENT.INPUT;
      break;
  }

  if (!Array.isArray(componentSubtype.options)) {
    if (componentSubtype.max_length !== undefined) {
      componentSubtype.options = {
        ...componentSubtype.options,
        maxLength: componentSubtype.max_length
      };
    }

    if (componentSubtype.regex !== undefined) {
      componentSubtype.options = {
        ...componentSubtype.options,
        regex: componentSubtype.regex
      };
    }
  }

  formatSubtype = componentSubtype;
  return formatSubtype;
};

export const payloadParser = (
  payloadMessage: PayloadParserMessage,
  {
    messagesProcessed,
    lastMessageIsCheckBox,
    setModalController,
    userLocationController: [userLocationVariable, setUserLocationVariable]
  }: PayloadStateController,
  { blocks, index }: PayloadBocks = { blocks: [], index: 0 }
): CustomMessage[] => {
  const parsedMessage: CustomMessage[] = [];
  let isLink = false;
  const payloadMessageType: string = payloadMessage.type ?? '';
  switch (payloadMessageType.toUpperCase()) {
    case TYPES.SECTION:
      if (
        payloadMessage?.text !== undefined &&
        typeof payloadMessage.text === 'object' &&
        'text' in payloadMessage.text &&
        payloadMessage?.text?.text !== ''
      ) {
        payloadMessage.text = getPayloadText(payloadMessage);
        const progress = checkForProgressBar(payloadMessage.text);
        if (progress !== '') {
          const component = {
            componentType: COMPONENT_TYPE.COMPONENT,
            componentData: {
              type: BOT_COMPONENT.PROGRESS_BAR,
              options: { progress }
            }
          };
          const from = { fromData: BOT_COMPONENT.PROGRESS_BAR };
          parsedMessage.push(createMessageFormat(component, from));
        } else {
          const { link, textMessage, fromData } = transformLinkMetaData(
            messagesProcessed,
            isLink,
            payloadMessage.text,
            BOT_MESSAGE.MESSAGE
          );
          const component = {
            componentType: COMPONENT_TYPE.MESSAGE,
            componentData: textMessage
          };
          const from = { fromData };
          isLink = link;
          parsedMessage.push(createMessageFormat(component, from));
        }
      }

      if (payloadMessage?.accessory !== undefined) {
        const component = {
          componentType: COMPONENT_TYPE.COMPONENT,
          componentData: generatePayloadSubtype(
            payloadMessage.accessory,
            lastMessageIsCheckBox
          )
        };
        const from = { fromData: BOT_COMPONENT.COMPONENT };
        parsedMessage.push(createMessageFormat(component, from));
      }

      if (isLink) {
        parsedMessage.push({
          id: uuidv4(),
          from: BOT_COMPONENT.COMPONENT,
          visible: true,
          component: {
            type: BOT_COMPONENT.PREPARING_DATA,
            title: 'Select an option',
            disable: false
          }
        });
      }
      break;
    case TYPES.IMAGE: {
      const component = {
        componentType: COMPONENT_TYPE.COMPONENT,
        componentData: {
          type: TYPES.IMAGE,
          options: {
            ...payloadMessage
          },
          disable: false
        }
      };
      const from = { fromData: BOT_COMPONENT.IMAGE };
      parsedMessage.push(createMessageFormat(component, from));
      break;
    }
    case TYPES.ACTIONS:
      if (lastMessageIsCheckBox.current && index !== undefined) {
        const lastBlock: Blocks['blocks'] = blocks[index - 1];
        if (
          lastBlock[0]?.from === BOT_COMPONENT.COMPONENT &&
          payloadMessage?.elements !== undefined &&
          payloadMessage?.elements?.length > 0
        ) {
          lastBlock[0].component.elements = payloadMessage.elements.map(
            (element: PayloadSubtypeTypes) => {
              return generatePayloadSubtype(element, lastMessageIsCheckBox);
            }
          );
        }
        lastMessageIsCheckBox.current = false;
      } else if (
        Array.isArray(payloadMessage.elements) &&
        payloadMessage.elements?.length > 0
      ) {
        payloadMessage.elements.forEach((element: PayloadSubtypeTypes) => {
          const component = {
            componentType:
              element.type === BOT_COMPONENT.PLAIN_TEXT
                ? COMPONENT_TYPE.MESSAGE
                : COMPONENT_TYPE.COMPONENT,
            componentData: generatePayloadSubtype(
              element,
              lastMessageIsCheckBox
            )
          };
          const from = { fromData: BOT_COMPONENT.COMPONENT };
          parsedMessage.push(createMessageFormat(component, from));
        });
      }
      break;
    case TYPES.INPUT:
      if (payloadMessage.element !== undefined) {
        const component = {
          componentType: COMPONENT_TYPE.COMPONENT,
          componentData: generatePayloadSubtype(
            payloadMessage.element as PayloadSubtypeTypes,
            lastMessageIsCheckBox,
            payloadMessage.label
          )
        };
        const from = { fromData: BOT_COMPONENT.COMPONENT };
        parsedMessage.push(createMessageFormat(component, from));
      }
      break;
    case TYPES.CONTEXT:
      if (
        index !== undefined &&
        Array.isArray(payloadMessage.elements) &&
        payloadMessage.elements?.length > 0
      ) {
        payloadMessage.elements.forEach((element: PayloadSubtypeTypes) => {
          const lastBlock: Blocks['blocks'] = blocks[index - 1];
          if (
            lastBlock[0] !== undefined &&
            lastBlock[0]?.from === BOT_COMPONENT.COMPONENT
          ) {
            lastBlock[0].component.options = {
              ...lastBlock[0].component.options,
              caption: getPayloadText(
                generatePayloadSubtype(element, lastMessageIsCheckBox)
              )
            };
          }
        });
      }
      if (
        COMPONENT_TYPE.COMPLEMENT in payloadMessage &&
        payloadMessage[COMPONENT_TYPE.COMPLEMENT] !== undefined
      ) {
        const payloadMessageWithComplement = JSON.parse(
          JSON.stringify(payloadMessage[COMPONENT_TYPE.COMPLEMENT])
        );
        const component = {
          componentType: COMPONENT_TYPE.COMPLEMENT,
          componentData: generatePayloadSubtype(
            payloadMessageWithComplement,
            lastMessageIsCheckBox
          )
        };
        const from = {
          visible: false,
          fromData: BOT_MESSAGE.SHOW_MESSAGE
        };
        parsedMessage.push(createMessageFormat(component, from));
      }
      break;
    case TYPES.MODAL:
      if (payloadMessage.element !== undefined) {
        if (payloadMessage.element?.action_id === MODAL_TYPES.SAVE_DATASET) {
          const payload = getPayload(payloadMessage);
          setModalController({
            type: MODAL_TYPES.SAVE_DATASET,
            payload
          });
        } else if (
          payloadMessage.element?.action_id === MODAL_TYPES.SAVE_MODEL
        ) {
          const payload: IntroduceModelTypes = {
            accountId: payloadMessage.element.accountId,
            userId: payloadMessage.element.userId,
            performanceMetric: payloadMessage.element.performanceMetric,
            dataContext: payloadMessage.element.dataContext,
            context: payloadMessage.element.context
          };
          setModalController({ type: MODAL_TYPES.SAVE_MODEL, payload });
        } else if (
          payloadMessage.element?.action_id === MODAL_TYPES.TRANSFORMATION
        ) {
          const payload = getPayload(payloadMessage);
          setModalController({ type: MODAL_TYPES.TRANSFORMATION, payload });
        } else if (
          payloadMessage.element.action_id === MODAL_TYPES.LOAD_MODELS
        ) {
          setUserLocationVariable({
            ...userLocationVariable,
            current: `${userLocationVariable.current}_${USER_TRACKING_LOCATION_NAMES.MYSPACE}`,
            previous: userLocationVariable.current
          });
          const payload = {};
          setModalController({ type: MODAL_TYPES.LOAD_MODELS, payload });
        }
      }
      break;
    default:
      throw new Error(
        `couldn't find a type of the message ${payloadMessageType} ${JSON.stringify(
          payloadMessage
        )}`
      );
  }

  return parsedMessage;
};

const getPayload = (payloadMessage: PayloadMessage): IntroduceDatasetTypes => {
  const payload: IntroduceDatasetTypes = {};
  if (payloadMessage.element?.dataset_url !== undefined) {
    payload.datasetUrl = payloadMessage.element.dataset_url;
  }
  if (payloadMessage.element?.dataset_id !== undefined) {
    payload.datasetId = payloadMessage.element.dataset_id;
  }
  if (payloadMessage.element?.original_dataset_id !== undefined) {
    payload.originalDatasetId = payloadMessage.element.original_dataset_id;
  }
  if (payloadMessage.element?.context !== undefined) {
    payload.context = JSON.stringify(payloadMessage.element.context);
  }
  return payload;
};

export const getLinkedComponent = (
  from: CustomMessage['from']
): ComponentType<RegularComponentTypes> | null => {
  const componentClass: string | undefined = COMPONENTS_CLASSES[from];
  if (componentClass === undefined) {
    return null;
  }
  const component = (props: RegularProps): ReactElement => {
    return (
      <div className={componentClass} key={props?.keys} style={props?.style}>
        {props?.children ?? null}
      </div>
    );
  };
  return component;
};

export const checkForProgressBar = (text: string): string => {
  const progressBarCharCodes = [9608, 9618];
  if (progressBarCharCodes.includes(text.charCodeAt(0))) {
    const isProgressbar = text.match(/.* ([0-9]*)%.*/);
    if (isProgressbar?.[1] !== undefined) {
      return isProgressbar[1];
    }
  }
  return '';
};

export const messageContainsLinkWord = (messageLink: string): boolean => {
  return (
    linkIdentifyMetaData.every((linkMetaData: string) =>
      messageLink.includes(linkMetaData)
    ) ||
    (messageLink.includes('wrong') && messageLink.includes('link'))
  );
};

export const generateBotMessageWithLink = (message: string): string[] => {
  if (message !== '' && typeof message === 'string') {
    const spacedWords = message?.split(' ');
    let words = '';
    let newMessages: string[] = [];
    if (spacedWords.length > 0) {
      spacedWords
        .map((element: string) => {
          if (element !== '' && element.includes('\n') && includeURL(element)) {
            return element
              .split('\n')
              .map((el: string) => (el === '' ? '\n\n' : el));
          }
          return element;
        })
        .flat()
        .forEach((element: string) => {
          if (element !== '') {
            if (includeURL(element)) {
              newMessages.push(words, element);
              words = '';
            } else {
              words += element + ' ';
            }
          }
        });
    }
    words !== '' && newMessages.push(' ' + words);
    newMessages = newMessages.map((element: string, index: number) => {
      if (newMessages.length === 1) element = element.trim();
      if (index === 0) element = element.trimStart();
      if (index === newMessages.length - 1) element = element.trimEnd();
      return element;
    });
    return newMessages;
  }
  return [message];
};
