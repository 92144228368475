import { type AxiosResponse } from 'axios';

import type {
  AssetListResponse,
  Assets,
  CrossTabsData,
  IntroduceDatasetTypes,
  IntroduceModelTypes,
  SaveUploadedTypes,
  SignedUploadUrl,
  TableData,
  TransformedData
} from 'common/interfaces/interfaces';

import type {
  Prediction,
  ScenarioResult
} from 'playground/interfaces/playground';

import type { PredictStatus } from 'common/interfaces/enums';
import type { MergeDatasetsKeys } from 'mergeDatasets/MergeDatasets';
import { type Filter } from 'models/Filter';
import { ExposeClient } from '../client/ExposeClient';
import { AuthenticationService } from './authentication/AuthenticationService';

export class ExposeService {
  private readonly authenticationService: AuthenticationService;
  private readonly exposeClient: ExposeClient;

  static instance: ExposeService;

  public static getInstance(): ExposeService {
    if (ExposeService.instance === undefined) {
      ExposeService.instance = new ExposeService();
    }

    return ExposeService.instance;
  }

  constructor() {
    this.authenticationService = AuthenticationService.getInstance();
    this.exposeClient = ExposeClient.getInstance();
  }

  get authToken(): string {
    return this.authenticationService.authToken;
  }

  async getUserModels(page = 1, size = 10): Promise<AssetListResponse> {
    return await this.exposeClient
      .getUserModels(this.authToken, page, size)
      .then((response) => {
        if (response.status === 200 && response.data?.data !== null) {
          return response.data;
        }
      });
  }

  async getUserDatasets(
    page = 1,
    size = 10,
    tags: string[] = []
  ): Promise<AssetListResponse> {
    return await this.exposeClient
      .getUserDatasets(this.authToken, page, size, tags)
      .then((response) => {
        if (response.status === 200 && response.data?.data !== null) {
          return response.data;
        }
      });
  }

  async saveUploadedDataSet(
    body: SaveUploadedTypes & IntroduceDatasetTypes
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .saveUploadedDataSet(body, this.authToken)
      .catch((error) => {
        return error('Error saving dataset');
      });
  }

  async saveModel(
    body: SaveUploadedTypes & IntroduceModelTypes
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .saveModel(body, this.authToken)
      .catch((error) => {
        return error('Error saving model');
      });
  }

  async mergeDatasetsKeyValidation(
    body: MergeDatasetsKeys[]
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .mergeDatasetsKeyValidation(body, this.authToken)
      .catch((error) => {
        return error('Error saving model');
      });
  }

  async deleteModel(modelId: string): Promise<AxiosResponse> {
    return await this.exposeClient
      .deleteModel(modelId, this.authToken)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async updateModel(
    body: SaveUploadedTypes,
    modelId: string
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .updateModel(body, modelId, this.authToken)
      .catch((error) => {
        return error('Error saving model');
      });
  }

  async updateDataset(
    body: SaveUploadedTypes,
    datasetId: string
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .updateDataset(body, datasetId, this.authToken)
      .catch((error) => {
        return error('Error saving dataset');
      });
  }

  async updateView(
    body: SaveUploadedTypes,
    datasetId: string
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .updateView(body, datasetId, this.authToken)
      .catch((error) => {
        return error('Error saving view');
      });
  }

  async deleteDataset(datasetId: string): Promise<AxiosResponse> {
    return await this.exposeClient
      .deleteDataset(datasetId, this.authToken)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async deleteView(datasetId: string): Promise<AxiosResponse> {
    return await this.exposeClient
      .deleteView(datasetId, this.authToken)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async predictStatus(
    jobId: string
  ): Promise<{ id: string; status: PredictStatus; error: string | never }> {
    return await this.exposeClient
      .predictStatus(jobId, this.authToken)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async predictResult(
    downloadId: string,
    inferenceUrl: string
  ): Promise<Prediction> {
    return await this.exposeClient
      .predictResult(downloadId, inferenceUrl, this.authToken)
      .then((response) => {
        return {
          targetColumn: response.data.target_column,
          prediction: response.data.prediction,
          isProbability: response.data.is_probability
        };
      });
  }

  async scenarioResult(body: Record<string, unknown>): Promise<ScenarioResult> {
    return await this.exposeClient
      .scenarioResult(this.authToken, body)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async getScenarioResult(
    downloadUrl: string
  ): Promise<Record<string, string>> {
    return await this.exposeClient
      .getScenarioResult(downloadUrl, this.authToken)
      .then((response) => {
        return response.data.recommendations;
      })
      .catch((error) => {
        return error;
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getSavedScenarios(modelId: string, target: string): Promise<any> {
    return await this.exposeClient
      .getSavedScenarios(modelId, target, this.authToken)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async getSignedDatasetUploadUrl(file: File): Promise<SignedUploadUrl> {
    return await this.exposeClient
      .getSignedDatasetUploadUrl(file, this.authToken)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async getDatasetById(datasetId: string): Promise<Assets> {
    return await this.exposeClient
      .getDatasetById(datasetId, this.authToken)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async getDatasetRows(
    datasetId: string,
    page = 1,
    size = 50,
    sortBy?: string,
    order?: string,
    showOnly?: Filter
  ): Promise<TableData | undefined> {
    return await this.exposeClient
      .getDatasetRows(
        this.authToken,
        datasetId,
        page,
        size,
        sortBy,
        order,
        showOnly
      )
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async getViewById(viewId: string): Promise<Assets> {
    return await this.exposeClient
      .getViewById(viewId, this.authToken)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async getViewRows(
    datasetId: string,
    viewId: string,
    page = 1,
    size = 50,
    sortBy?: string,
    order?: string,
    filter?: Filter
  ): Promise<TableData> {
    return await this.exposeClient
      .getViewRows(
        this.authToken,
        datasetId,
        viewId,
        page,
        size,
        sortBy,
        order,
        filter
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  async saveTransformedView(
    datasetId: string,
    viewId: string,
    body: SaveUploadedTypes & TransformedData
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .saveTransformedView(datasetId, viewId, body, this.authToken)
      .catch((error) => {
        return error('Error saving transformed view');
      });
  }

  async saveTransformedDataset(
    id: string,
    body: SaveUploadedTypes & TransformedData
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .saveTransformedDataset(id, body, this.authToken)
      .catch((error) => {
        return error('Error saving transformed dataset');
      });
  }

  async overwriteDataset(
    id: string,
    body: SaveUploadedTypes & TransformedData
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .overwriteDataset(id, body, this.authToken)
      .catch((error) => {
        return error('Error overwriting dataset');
      });
  }

  async checkColumnTypeChange(
    columnTypeData: { type: string; column: string },
    datasetId: string
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .checkColumnTypeChange(datasetId, columnTypeData, this.authToken)
      .catch((error) => {
        return error;
      });
  }

  async generateCrossTabsFile(
    crossTabsData: CrossTabsData
  ): Promise<AxiosResponse> {
    return await this.exposeClient
      .generateCrossTabsFile(crossTabsData, this.authToken)
      .catch((error) => {
        return error;
      });
  }

  async generateCrossDownload(crossTabsUrl: string): Promise<AxiosResponse> {
    return await this.exposeClient
      .generateCrossDownload(crossTabsUrl, this.authToken)
      .catch((error) => {
        return error;
      });
  }
}
