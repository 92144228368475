import React, { useRef, useState, type ReactElement } from 'react';

import type { BotComponentSubmit } from 'chat/Chat';
import { USER_MESSAGE } from 'chat/interfaces/enums';
import type { Option } from 'chat/interfaces/messages';
import { MODAL_TYPES } from 'common/interfaces/enums';

import { AssistantApiService } from 'chat/services/AssistantApiService';

import { Check, KeyboardArrowDown } from '@mui/icons-material';
import { MenuItem, Select } from '@mui/material';
import {
  TRACKED_USER_ACTION_PREDICT_FLOW,
  TRACKED_USER_ACTION_TRIGGERS,
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { lastResponseAtom } from 'chat/atoms/atomLastResponse';
import CustomButton from 'common/button/CustomButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GAUserEvent } from 'utils/utils';

interface CustomSelectIslandProps extends BotComponentSubmit {
  options: Option[];
  collapseIsland: () => void;
  openModal: (type: MODAL_TYPES) => void;
}

const CustomSelectIsland = ({
  options,
  collapseIsland,
  addNewMessages,
  openModal
}: CustomSelectIslandProps): ReactElement => {
  const lastResponse = useRecoilValue(lastResponseAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const [optionSelected, setOptionSelected] = useState<string>('');
  const assistantApiService = AssistantApiService.getInstance();
  const selectField = useRef<HTMLInputElement>(null);

  const submitValue = (): void => {
    addNewMessages(optionSelected, USER_MESSAGE.MESSAGE, true);
    if (optionSelected === 'Predictive playground') {
      GAUserEvent(
        `${userLocationVariable.current}_${TRACKED_USER_ACTION_PREDICT_FLOW.OPEN_PLAYGROUND}`
      );
      openModal(MODAL_TYPES.PLAYGROUND);

      if (selectField.current !== null) {
        selectField.current.value = '';
      }
    } else {
      collapseIsland();
      assistantApiService.selectOption(lastResponse.blocks, optionSelected);

      // Send button is disabled after clearing optionSelected
      // except for the case when the user selects the predictive playground
      setOptionSelected('');

      if (
        lastResponse.blocks[0]?.accessory?.action_id ===
          TRACKED_USER_ACTION_TRIGGERS.INITIAL ||
        lastResponse.blocks[0]?.accessory?.action_id ===
          TRACKED_USER_ACTION_TRIGGERS.CHANGE
      ) {
        const selectedOption = options.findIndex(
          (option) => option.value === optionSelected
        );
        if (selectedOption === 0) {
          // If the user selects the first option, the user location is updated to OPTIMISE
          setUserLocationVariable({
            ...userLocationVariable,
            current: USER_TRACKING_LOCATION_NAMES.OPTIMISE,
            previous: userLocationVariable.current
          });
        } else if (selectedOption === 1) {
          // If the user selects the second option, the user location is updated to PREDICT
          setUserLocationVariable({
            ...userLocationVariable,
            current: USER_TRACKING_LOCATION_NAMES.PREDICT,
            previous: userLocationVariable.current
          });
        }
      }
    }
  };

  const classes = {
    width: '100%',
    height: '48px',
    margin: '12px 0px',
    background: '#FFFFFF',
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A82AAB !important',
      borderRadius: '8px'
    }
  };

  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: '100%',
        boxSizing: 'borderBox',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        position: 'absolute',
        background: '#FFFFFF',
        border: '1px solid #F2F4F7',
        boxShadow:
          '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
        borderRadius: '8px',
        margin: '-5px 0px',
        '& .MuiList-root': {
          maxHeight: '20rem',
          width: '100%',
          '::-webkit-scrollbar': {
            width: '0.4em'
          },

          '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },

          '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, .5)'
          }
        },
        '& .MuiButtonBase-root': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 14px',
          gap: '8px',
          height: '44px',
          flex: 'none',
          order: '0',
          alignSelf: 'stretch',
          flexGrow: '0',
          borderRadius: '8px',
          marginBottom: '3px',
          '&:hover': {
            background: 'rgba(168, 42, 171, 0.73)',
            color: 'white'
          }
        },
        '& .Mui-selected': {
          background: '#F2F4F7',
          '&:hover': {
            background: 'rgba(168, 42, 171, 0.73)',
            color: 'white'
          }
        }
      }
    },
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'top'
    },
    transformOrigin: {
      horizontal: 'left',
      vertical: 'bottom'
    }
  };

  return (
    <React.Fragment>
      <Select
        ref={selectField}
        defaultValue=""
        sx={classes}
        onChange={(e) => {
          setOptionSelected(e.target.value);
        }}
        // @ts-expect-error MUI type does not expect the value prop but it works
        MenuProps={MenuProps}
        IconComponent={KeyboardArrowDown}
      >
        {options.map((option: Option) => (
          <MenuItem
            style={{ fontFamily: 'ft-system-regular' }}
            key={`option-${option.value}`}
            value={option?.value}
          >
            {option?.label}
            {optionSelected === option.value ? <Check /> : <></>}
          </MenuItem>
        ))}
      </Select>
      <CustomButton
        disabled={optionSelected === ''}
        style={{ maxWidth: 'none' }}
        onClick={() => {
          submitValue();
        }}
      >
        Send
      </CustomButton>
    </React.Fragment>
  );
};

export default CustomSelectIsland;
