import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar, { type ToolbarProps } from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import AIZWEILOGO from 'assets/imgs/aizwei-logo-black.svg';
import { modalControllerAtom } from 'atoms/atomModalController';
import {
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { collapseMagicIslandDerived } from 'chat/atoms/atomMagicMessage';
import { type User } from 'models/User';
import { useEffect, useState, type ReactElement } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { GAUserEvent } from '../utils/utils';
import CustomButton from './button/CustomButton';
import { ICON_TYPE, MODAL_TYPES } from './interfaces/enums';

const StyledToolbar = styled(Toolbar)<ToolbarProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const ACCOUNT = 'Account';
const LOGOUT = 'Logout';
const DATASETS = 'Datasets';
const MODELS = 'Models';

const marketPlaceUrl = process.env.REACT_APP_MARKETPLACE_BASE_URL;
const marketPlacePrefixUri =
  process.env.REACT_APP_MARKETPLACE_BASE_URL_PREFIX_URI;
const plaionAccountId = process.env.REACT_APP_PLAION_ACCOUNT_ID;

const sessionButtonStyles = {
  justifyContent: 'flex-start',
  border: 'none'
};

interface NavBarProps {
  disabled: boolean;
  logged: boolean;
  user: User | null;
  onChange: () => void;
}

const NavBar = ({
  disabled,
  logged,
  user,
  onChange
}: NavBarProps): ReactElement => {
  const accountID = user?.accountId ?? user?.account?.id;
  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);
  const setCollapseMagicIsland = useSetRecoilState(collapseMagicIslandDerived);

  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [openSessionAdmin, setOpenSessionAdmin] = useState<boolean>(false);
  const [openMySpace, setOpenMySpace] = useState<boolean>(false);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  useEffect(() => {
    setOpenSessionAdmin(false);
    setOpenMySpace(false);
  }, [disabled]);

  const handleMySpace = (target?: string): void => {
    setOpenSessionAdmin(false);
    setOpenMySpace(!openMySpace);
    if (modalController.type === MODAL_TYPES.CLOSE) {
      if (target === DATASETS) {
        GAUserEvent('DATASETS');
        setCollapseMagicIsland(false);
        setModalController({
          type: MODAL_TYPES.LOAD_DATASETS,
          payload: { disable_asset_button: true }
        });
      } else if (target === MODELS) {
        GAUserEvent('MODELS');
        setModalController({
          type: MODAL_TYPES.LOAD_MODELS,
          payload: { disable_asset_button: true }
        });
        setCollapseMagicIsland(false);
      }
      if (target !== undefined) {
        setUserLocationVariable({
          ...userLocationVariable,
          current: USER_TRACKING_LOCATION_NAMES.MYSPACE,
          previous: userLocationVariable.current
        });
      }
    }
  };

  const handleSessionAdmin = (target?: string): void => {
    setOpenMySpace(false);
    setOpenSessionAdmin(!openSessionAdmin);
    if (target === LOGOUT) {
      GAUserEvent('LOGOUT');
      onChange();
    } else if (target === ACCOUNT) {
      GAUserEvent('OPEN_ACCOUNT');
      openMarketPlace();
    }
  };

  const openMarketPlace = (): void => {
    if (marketPlaceUrl !== undefined && marketPlacePrefixUri !== undefined) {
      window.location.href = `${marketPlaceUrl}${marketPlacePrefixUri}`;
    }
  };

  return (
    <AppBar
      position="absolute"
      color="transparent"
      elevation={0}
      style={{ zIndex: '10000' }}
    >
      <StyledToolbar
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '1em'
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          disabled={isDisabled}
          onClick={() => {
            if (!isDisabled) {
              setDisabled(true);
              onChange();
            }
          }}
          sx={{ mr: 2 }}
        >
          <img src={AIZWEILOGO} alt="AIZWEI LOGO" />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1em'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            {logged && user !== null ? (
              <>
                <CustomButton
                  variant="secondary"
                  icon={{
                    type: openMySpace
                      ? ICON_TYPE.CHEVRON_UP
                      : ICON_TYPE.CHEVRON_DONW,
                    position: 'right'
                  }}
                  onClick={() => {
                    handleMySpace();
                  }}
                >
                  My Space
                </CustomButton>
                <CustomButton
                  variant="secondary"
                  icon={{
                    type: ICON_TYPE.USER,
                    position: 'left'
                  }}
                  onClick={() => {
                    handleSessionAdmin();
                  }}
                ></CustomButton>
              </>
            ) : (
              <></>
            )}
          </Box>
          {openMySpace ? (
            <Box
              className="navbar-open-elements"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <CustomButton
                variant="secondary"
                onClick={() => {
                  handleMySpace(DATASETS);
                }}
                style={{
                  ...sessionButtonStyles,
                  textTransform: 'none',
                  maxWidth: 'none'
                }}
              >
                {DATASETS}
              </CustomButton>
              <CustomButton
                variant="secondary"
                onClick={() => {
                  handleMySpace(MODELS);
                }}
                style={{
                  ...sessionButtonStyles,
                  textTransform: 'none',
                  maxWidth: 'none'
                }}
              >
                {MODELS}
              </CustomButton>
              {accountID === plaionAccountId ? (
                <CustomButton
                  style={{
                    ...sessionButtonStyles,
                    textTransform: 'none',
                    maxWidth: 'none'
                  }}
                >
                  <a
                    className="ft-bold"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    href="https://dailightai.sharepoint.com/:b:/s/AIZWEI2/EVZRsfsCPD1FqjHtGCl-3t0Bz0CyjjQvginqs3AbUksCGw?e=N9ZRGN"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Report
                  </a>
                </CustomButton>
              ) : null}
            </Box>
          ) : (
            <></>
          )}
          {openSessionAdmin ? (
            <Box
              className="navbar-open-elements"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <CustomButton
                variant="secondary"
                icon={{
                  type: ICON_TYPE.USER,
                  position: 'left'
                }}
                style={{
                  ...sessionButtonStyles,
                  textTransform: 'none',
                  maxWidth: 'none'
                }}
                onClick={() => {
                  handleSessionAdmin(ACCOUNT);
                }}
              >
                {ACCOUNT}
              </CustomButton>
              <CustomButton
                variant="secondary"
                icon={{
                  type: ICON_TYPE.LOG_OUT,
                  position: 'left'
                }}
                style={{
                  ...sessionButtonStyles,
                  textTransform: 'none',
                  maxWidth: 'none'
                }}
                onClick={() => {
                  handleSessionAdmin(LOGOUT);
                }}
              >
                {LOGOUT}
              </CustomButton>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </StyledToolbar>
    </AppBar>
  );
};

export default NavBar;
