import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Divider,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { modalControllerAtom } from 'atoms/atomModalController';
import SummaryLayer from 'bulkPredictions/components/summary-layer/SummaryLayer';
import Chart from 'chat/components/chart/Chart';
import { ICON_TYPE, MODAL_TYPES, ModalSize } from 'common/interfaces/enums';
import React, {
  useEffect,
  useState,
  type ReactElement,
  useCallback
} from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
  GAUserEvent,
  downloadDocumentFromUrl,
  transformDataToPlot
} from 'utils/utils';
import { type Insight } from './interfaces/insigths';

import {
  TRACKED_USER_ACTION_OPTIMISE_FLOW,
  USER_TRACKING_LOCATION_NAMES,
  USER_TRACKING_SUMMARY_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { previewfiltersStateAtom } from 'bulkPredictions/atoms/atomPreviewfilersState';
import Image from 'chat/components/Image/Image';
import type { MessageDataPlot } from 'chat/interfaces/messages';
import CustomButton from 'common/button/CustomButton';
import ModalComposed from 'common/modal/ModalComposed';
import './Insights.scss';

interface InsightsProps {
  goal: string;
  statement: {
    metric: string;
    column: string;
    disregard: string[];
  };
  insights: Insight[];
  plot: MessageDataPlot;
  downloadURL: string;
  datasetId: string;
  originalDatasetId: string;
  features: Record<string, string>;
}

const PREVIEW_INSIGHTS = 'PREVIEW_INSIGHTS';
const PREVIEW_DATA = 'PREVIEW_DATA';

const Insights = ({
  goal,
  statement,
  insights,
  plot,
  downloadURL,
  datasetId,
  originalDatasetId,
  features
}: InsightsProps): ReactElement => {
  const modalController = useRecoilValue(modalControllerAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const resetAtomModalController = useResetRecoilState(modalControllerAtom);
  const resetAtomFilterOptions = useResetRecoilState(previewfiltersStateAtom);

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [currentInsight, setCurrentInsight] = useState<Insight | undefined>(
    undefined
  );
  const [view, setView] = useState<string>(PREVIEW_INSIGHTS);
  const [disregardAnchor, setDisregardAnchor] = useState<null | HTMLElement>(
    null
  );
  const disregardOpen = Boolean(disregardAnchor);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);

  const [showMore, setShowMore] = useState<boolean>(false);

  /*
    useCallback updates every time the ref is updated.
    It will trigger once as null and then again when the element is fully rendered
  */
  const goalTextElement = useCallback((node: null | HTMLElement) => {
    if (node !== null) {
      const computed = window.getComputedStyle(node);
      // We know at this point that the render is done so this is a safe operation
      const showMoreBtn: HTMLElement = document.getElementsByClassName(
        'insights-statement__show-more-btn'
      )[0] as HTMLElement;
      if (showMoreBtn !== undefined) {
        showMoreBtn.style.display =
          parseInt(computed.maxWidth) > node.offsetWidth ? 'none' : 'block';
      }
    }
  }, []);
  const correlations = [
    {
      label: 'Strong',
      color: '#02044c'
    },
    {
      label: 'Medium',
      color: '#0f46b1'
    },
    {
      label: 'Weak',
      color: '#007682'
    }
  ];

  const isInsights = userLocationVariable.current.includes(
    USER_TRACKING_LOCATION_NAMES.INSIGHTS
  );

  useEffect(
    () => () => {
      resetAtomFilterOptions();
    },
    []
  );

  const handleCloseModal = (): void => {
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_SUMMARY_ACTIONS.CLOSE}`
    );
    setUserLocationVariable({
      ...userLocationVariable,
      current: userLocationVariable.previous,
      previous: userLocationVariable.current
    });
    resetAtomModalController();
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    if (newValue === 0 && currentInsight !== undefined) {
      setCurrentInsight(undefined);
    }
    setCurrentTab(newValue);
  };

  const openDisregard = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setDisregardAnchor(event.currentTarget);
  };

  const closeDisregard = (): void => {
    setDisregardAnchor(null);
  };

  const headerComponent = (
    <Box id="insights-header">
      <Typography style={{ fontSize: 14 }}>Insights</Typography>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Typography style={{ fontSize: 18, fontWeight: 400 }}>
        Optimise a business goal
      </Typography>
      <CloseIcon
        style={{ marginLeft: 'auto', cursor: 'pointer' }}
        onClick={handleCloseModal}
      />
    </Box>
  );

  const bodyComponent = (
    <Box
      id="insights-body"
      style={{
        gridTemplateRows: showMore
          ? '[header] max-content [body] auto'
          : '[header] 68px [body] auto'
      }}
    >
      <Box id="insights-body__header">
        <Box id="insights-body__header--statement">
          <Typography component="div" className="insights-statement">
            <Box fontWeight="bold">Optimising Goal</Box>
            <Box
              ref={goalTextElement}
              className={`insights-statement__text ${
                showMore ? 'show-more-text' : ''
              }`}
            >
              {statement.metric}{' '}
              {statement.column !== undefined && statement.column.length !== 0
                ? statement.column
                : goal}
            </Box>
            <div
              className="insights-statement__show-more-btn"
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              Show More
            </div>
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          {statement.disregard.length !== 0 ? (
            <Box className="insights-statement">
              <CustomButton
                id="disregard-button"
                variant="tertiary"
                aria-controls={disregardOpen ? 'disregard-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={disregardOpen ? 'true' : undefined}
                onClick={(event?: React.MouseEvent<HTMLButtonElement>) => {
                  if (event !== undefined) {
                    openDisregard(event);
                  }
                }}
                icon={{
                  type: ICON_TYPE.CHEVRON_DONW,
                  position: 'right'
                }}
              >
                Disregarded column(s)
              </CustomButton>
              <Menu
                id="disregard-menu"
                anchorEl={disregardAnchor}
                open={disregardOpen}
                onClose={closeDisregard}
              >
                {statement.disregard.map((column, index) => {
                  return <MenuItem key={index}>{column}</MenuItem>;
                })}
              </Menu>
            </Box>
          ) : (
            <Typography className="insights-statement">
              <Box fontWeight="bold">Disregarded column(s)</Box>
              <span>None</span>
            </Typography>
          )}
        </Box>
      </Box>
      {view === PREVIEW_INSIGHTS && (
        <>
          <Box id="insights-body__content">
            <Box id="insights-body-details">
              <Typography component="div" className="insights-title-text">
                <Box color="black" fontWeight="bold" fontSize="24px">
                  Insights
                </Box>
                We have analysed your data and have found some important
                patterns. Here are some actions that will help you to accomplish
                your desired result:
              </Typography>
              <Box id="insights-description-container">
                {insights.length > 0 &&
                  insights.map((insight, index) => {
                    return (
                      <Box
                        key={index}
                        className={`insights-title-descriptions ${
                          insight.title === currentInsight?.title
                            ? 'active-insight'
                            : ''
                        }`}
                        onClick={() => {
                          if (currentInsight?.title === insight.title) {
                            setCurrentInsight(undefined);
                            setCurrentTab(0);
                          } else {
                            setCurrentInsight({ ...insight, position: index });
                            setCurrentTab(1);
                            GAUserEvent(
                              `${userLocationVariable.current}_${TRACKED_USER_ACTION_OPTIMISE_FLOW.SELECT_PLOT}`
                            );
                          }
                        }}
                      >
                        <Box className="insights-title-number">{`#${
                          index + 1
                        }`}</Box>
                        {insight.description !== '' ? (
                          <Box className="insight-text">
                            {insight.description}
                          </Box>
                        ) : null}
                      </Box>
                    );
                  })}
              </Box>
              {insights.length > 0 ? (
                <Alert className="insights-alert" severity="info">
                  Only showing insights for factors with correlation threshold
                  above 0.3
                </Alert>
              ) : (
                <Alert className="insights-alert" severity="warning">
                  We&apos;re sorry, but no insights can be displayed because all
                  factors have a correlation below the 0.3 threshold needed for
                  valuable and trusted insights. Your data may not be sufficient
                  or accurate enough. Please review it or consider using a
                  different source.
                </Alert>
              )}
            </Box>
            <Box id="insights-body-plots">
              <Tabs
                className="insights-tab__header"
                value={currentTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Correlation Importance Plot"
                  id={'insights-tab-index-0'}
                  style={{ fontWeight: 'bold' }}
                />
                {currentInsight !== undefined ? (
                  <Tab
                    key={1}
                    id={'insights-tab-index-1'}
                    label={currentInsight?.title ?? ''}
                  />
                ) : null}
              </Tabs>
              <div
                className="insights-tab__plot"
                role="tabpanel"
                hidden={currentTab !== 0}
              >
                <Chart
                  chartID="chart"
                  isChat={false}
                  data={transformDataToPlot(plot)}
                />
              </div>
              {currentInsight !== undefined ? (
                <div key={1} role="tabpanel" hidden={currentTab === 0}>
                  <Box className="insights-tab-images">
                    <div className="insights-tab-images-with-button">
                      <CustomButton
                        variant="outlined"
                        className="insights-tab-images-expand-button"
                        onClick={() => {
                          setShowImageModal(true);
                        }}
                        icon={{
                          type: ICON_TYPE.EXPAND,
                          position: 'left',
                          style: {
                            width: '30px',
                            height: '30px'
                          }
                        }}
                      ></CustomButton>

                      <img
                        src={currentInsight.image_url ?? ''}
                        alt={currentInsight.title ?? ''}
                      />
                    </div>

                    {showImageModal &&
                      currentInsight.image_url !== undefined && (
                        <Image
                          options={{ image_url: currentInsight.image_url }}
                          open={showImageModal}
                          closeModal={setShowImageModal}
                          onlyModal={true}
                        />
                      )}
                  </Box>
                </div>
              ) : null}{' '}
              <Box id="insights-body-plot-legend" hidden={currentTab !== 0}>
                <Typography component="div" className="insights-plot-legend">
                  <Box fontWeight="bold">Correlation:</Box>
                  {correlations.map((correlation, index) => {
                    return (
                      <Box key={index} className="insights-plot-legend__item">
                        <Box
                          className="insights-plot-legend__color"
                          style={{
                            backgroundColor: correlation.color
                          }}
                        ></Box>
                        {correlation.label}
                      </Box>
                    );
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {view === PREVIEW_DATA && features !== undefined && (
        <SummaryLayer
          datasetIds={{
            datasetId,
            originalDatasetId
          }}
          asset={{ features }}
          activeSwitch={false}
          online={true}
        />
      )}
    </Box>
  );

  const footerComponent = (
    <Box id="insights-footer">
      <Box style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
        {datasetId !== undefined && datasetId !== '' && (
          <CustomButton
            variant="secondary"
            onClick={() => {
              const isPreview = view === PREVIEW_DATA;
              if (isPreview) {
                resetAtomFilterOptions();
              }

              GAUserEvent(
                `${userLocationVariable.current}_${
                  isInsights
                    ? TRACKED_USER_ACTION_OPTIMISE_FLOW.OPEN_SUMLAYER
                    : TRACKED_USER_ACTION_OPTIMISE_FLOW.OPEN_INSIGHTS
                }`
              );

              setUserLocationVariable({
                ...userLocationVariable,
                current: `${userLocationVariable.previous}_${
                  isInsights
                    ? USER_TRACKING_LOCATION_NAMES.SUMLAYER
                    : USER_TRACKING_LOCATION_NAMES.INSIGHTS
                }`
              });

              setView(isPreview ? PREVIEW_INSIGHTS : PREVIEW_DATA);
            }}
            icon={{
              type:
                view === PREVIEW_INSIGHTS
                  ? ICON_TYPE.TABLE
                  : ICON_TYPE.BAR_CHART,
              position: 'left'
            }}
          >
            {view === PREVIEW_INSIGHTS ? 'Preview Data' : 'Back to Insights'}
          </CustomButton>
        )}
        <CustomButton
          variant="secondary"
          onClick={() => {
            GAUserEvent(
              `${userLocationVariable.current}_${TRACKED_USER_ACTION_OPTIMISE_FLOW.DOWNLOAD_REPORT}`
            );
            downloadDocumentFromUrl(downloadURL, `Optimise ${goal} results`);
          }}
          icon={{
            type: ICON_TYPE.DOWNLOAD01,
            position: 'left'
          }}
        >
          Download Report
        </CustomButton>
      </Box>
      <CustomButton onClick={handleCloseModal}>Close</CustomButton>
    </Box>
  );

  return (
    <ModalComposed
      className="insights-wrapper"
      size={ModalSize.COMPOSED_FULL}
      open={modalController.type === MODAL_TYPES.INSIGHTS}
      header={headerComponent}
      body={bodyComponent}
      footer={footerComponent}
    />
  );
};
export default Insights;
