export const heightResponsive = {
  '@media (min-height: 800px)': {
    '.insights-section-one': {
      '& .wrapp-preview-table': {
        maxHeight: '42vh'
      }
    },
    '.transformation-block': {
      '& .wrapp-preview-table': {
        maxHeight: '62vh'
      }
    }
  },
  '@media (max-height: 800px)': {
    '.table-container': {
      maxHeight: '330px!important'
    },
    '.transformation-block': {
      maxHeight: '401px!important',
      '& .wrapp-preview-table': {
        tbody: {
          height: '47vh!important'
        }
      },
      '& .transform-column-container': {
        maxHeight: '380px!important'
      },
      '& .transformation-block-manager-content': {
        maxHeight: '380px!important'
      },
      '& .transformation-manager-selected-content': {
        maxHeight: '325px!important',
        minHeight: '300px'
      }
    },
    '.merge-dataset': {
      '& .MuiTableContainer-root': {
        maxHeight: '190px!important'
      }
    },
    '.insights-section-one': {
      '& .wrapp-rows-table': {
        maxHeight: '300px!important'
      },
      '& .wrapp-preview-table': {
        height: '40vh'
      }
    }
  },

  '@media (max-height: 600px)': {
    '.table-container': {
      maxHeight: '250px!important'
    },
    '.insights-section-one': {
      '& .wrapp-rows-table': {
        maxHeight: '300px!important'
      },
      '& .wrapp-preview-table': {
        height: '30vh'
      }
    }
  }
};

export const widthResposive = {
  '@media (max-width: 975px)': {
    maxWidth: '700px!important'
  },
  '@media (max-width: 1024px)': {
    maxWidth: '975px!important'
  }
};
