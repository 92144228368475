import React, {
  useRef,
  useState,
  type Dispatch,
  type ReactElement,
  type SetStateAction
} from 'react';

import { Box, Divider, Radio, Typography } from '@mui/material';

import type {
  IntroduceDatasetTypes,
  IntroduceModelTypes,
  NotifyBoxTypes,
  SaveUploadedTypes,
  SendCombineColumns,
  SendTransformation,
  TransformedData
} from 'common/interfaces/interfaces';

import {
  ACCESS_TYPES,
  MODAL_INTENTS,
  MODAL_TYPES,
  ModalSize,
  RESTRICTIONS
} from 'common/interfaces/enums';

import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';

import ModalComposed from 'common/modal/ModalComposed';
import AssetsForm from 'mySpace/components/assetsInputsByIntent/AssetsForm';

import {
  createErrorNotification,
  createInfoNotification,
  notifyMessageAtom
} from 'atoms/atomMessageError';
import { modalControllerAtom } from 'atoms/atomModalController';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';
import { ExposeService } from 'services/ExposeService';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import { GAUserEvent } from '../../utils/utils';

import {
  USER_TRACKING_FENG_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import 'common/Common.scss';
import './Save.scss';

interface SaveProps {
  isTransform?: [boolean, Dispatch<SetStateAction<boolean>>];
  datasetData?: {
    datasetId: string;
    originalDatasetId: string;
    datasetUrl: string;
    dataManagementFlow: boolean;
  };
  newTransformationState?: {
    transformData: Array<SendTransformation | SendCombineColumns>;
  };
}

const Save = ({
  isTransform,
  datasetData,
  newTransformationState = { transformData: [] }
}: SaveProps): ReactElement => {
  const [transformOpen, setOpenTransform] = isTransform ?? [
    false,
    function () {
      return null;
    }
  ];
  const modalController = useRecoilValue(modalControllerAtom);
  const resetAtomModalController = useResetRecoilState(modalControllerAtom);
  const setNotifyMessage = useSetRecoilState(notifyMessageAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const assistantService = AssistantApiService.getInstance();
  const authenticationService = AuthenticationService.getInstance();
  const exposeService = ExposeService.getInstance();

  const [name, setName] = useState<string>('');
  const [validName, setValidName] = useState<boolean>(false);
  const [sendData, setSendData] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<null | NotifyBoxTypes>(null);

  const [visibility, setVisibility] = useState<ACCESS_TYPES>(
    ACCESS_TYPES.PRIVATE
  );
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const btnSave = useRef<HTMLButtonElement>(null);

  const sharedResources = authenticationService.accountRestrictionByKey(
    RESTRICTIONS.SHARED_RESOURCES
  );
  const access = authenticationService.getAccess();
  const [tags, setTags] = useState<string[]>([]);

  const isDataSet =
    modalController.type === MODAL_TYPES.SAVE_DATASET ||
    modalController.type === MODAL_TYPES.TRANSFORMATION;
  const introduceIntent = isDataSet
    ? MODAL_INTENTS.INTRODUCED_DATASET
    : MODAL_INTENTS.SAVED_MODEL;

  const notifyUserDataManagementFlow = (
    isError: boolean,
    message: string
  ): void => {
    // Do not disturb chatbot conversation if changes are being made from MySpace
    if (isError) {
      createErrorNotification(
        `Something went wrong when saving modifications. Error: ${message}`,
        setNotifyMessage
      );
    } else {
      createInfoNotification(
        'Modifications saved successfully',
        setNotifyMessage
      );
    }
    resetAtomModalController();
  };

  const failedExpose = (error: Error): void => {
    if (
      datasetData?.dataManagementFlow !== undefined &&
      datasetData?.dataManagementFlow !== null &&
      datasetData?.dataManagementFlow
    ) {
      notifyUserDataManagementFlow(true, error.message);
      return;
    }
    setSendData(false);
    setMessageError({ type: 'error', message: error.message });
  };

  const successSaveExpose = (data?: {
    dataset_url: string;
    dataset_id: string;
  }): void => {
    if (
      datasetData?.dataManagementFlow !== undefined &&
      datasetData?.dataManagementFlow !== null &&
      datasetData?.dataManagementFlow
    ) {
      notifyUserDataManagementFlow(false, '');
      return;
    }
    resetAtomModalController();
    GAUserEvent('SAVED_DATASET');
    assistantService.continueConversationAfterSaveModal(
      introduceIntent,
      true,
      data
    );
  };

  const sucessTransformExpose = (transformedData: {
    data: { viewUrl: string; id: string };
  }): void => {
    const { data } = transformedData;
    if (
      datasetData?.dataManagementFlow !== undefined &&
      datasetData?.dataManagementFlow !== null &&
      datasetData?.dataManagementFlow
    ) {
      notifyUserDataManagementFlow(false, '');
      return;
    }
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.SAVE}`
    );
    setUserLocationVariable({
      ...userLocationVariable,
      current: userLocationVariable.previous,
      previous: userLocationVariable.current
    });
    closeModal();
    resetAtomModalController();
    assistantService.continueConversationAfterSaveModal(
      introduceIntent,
      true,
      { dataset_id: data.id, dataset_url: data.viewUrl },
      true
    );
  };

  const handleSave = (): void => {
    setSendData(true);
    let description = '';
    if (
      descriptionRef.current !== null &&
      descriptionRef.current.value !== ''
    ) {
      description = descriptionRef.current.value;
    }

    let collectedSaveData: SaveUploadedTypes &
      IntroduceDatasetTypes &
      IntroduceModelTypes &
      TransformedData = {
      name,
      description,
      visibility,
      tags
    };

    if (
      transformOpen &&
      datasetData !== undefined &&
      datasetData.originalDatasetId !== undefined &&
      datasetData.datasetId !== undefined &&
      newTransformationState.transformData.length > 0
    ) {
      collectedSaveData.transformations = newTransformationState.transformData;

      if (datasetData.datasetId !== datasetData.originalDatasetId) {
        exposeService
          .saveTransformedView(
            datasetData.originalDatasetId,
            datasetData.datasetId,
            collectedSaveData
          )
          .then(sucessTransformExpose)
          .catch(failedExpose);
      } else {
        exposeService
          .saveTransformedDataset(
            datasetData.originalDatasetId,
            collectedSaveData
          )
          .then(sucessTransformExpose)
          .catch(failedExpose);
      }
    } else {
      if (isDataSet) {
        GAUserEvent('SAVING_DATASET');
        collectedSaveData.datasetId = modalController.payload.datasetId;
        collectedSaveData.datasetUrl = modalController.payload.datasetUrl;
        collectedSaveData.context = modalController.payload.context;
        exposeService
          .saveUploadedDataSet(collectedSaveData)
          .then(() => {
            successSaveExpose({
              dataset_id: collectedSaveData.datasetId as string,
              dataset_url: collectedSaveData.datasetUrl as string
            });
          })
          .catch(failedExpose);
      } else {
        collectedSaveData = {
          ...collectedSaveData,
          accountId: modalController.payload.accountId,
          userId: modalController.payload.userId,
          performanceMetric: modalController.payload.performanceMetric,
          dataContext: modalController.payload.dataContext,
          context: modalController.payload.context
        };
        GAUserEvent('SAVING_MODEL');
        exposeService
          .saveModel(collectedSaveData)
          .then(() => {
            successSaveExpose();
          })
          .catch(failedExpose);
      }
    }
  };

  const closeModal = (): void => {
    if (transformOpen) {
      setOpenTransform(false);
    } else {
      const disableAssetButton: boolean =
        modalController?.payload?.disable_asset_button ?? false;
      if (!disableAssetButton) {
        assistantService.createCountDown().catch(console.error);
      }
      if (!sendData) {
        const collectedSaveData = {
          dataset_id: modalController.payload.datasetId,
          dataset_url: modalController.payload.datasetUrl
        };
        resetAtomModalController();
        assistantService.continueConversationAfterSaveModal(
          introduceIntent,
          false,
          collectedSaveData
        );
      }
    }
  };

  const onEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (validName) handleSave();
    }
  };

  const headerComponent = (
    <Box className="save-header">
      <Typography className="modalTitle color-dark">
        Save {isDataSet ? 'dataset' : 'model'}
      </Typography>
      <CloseIcon
        onClick={closeModal}
        style={{ marginLeft: 'auto', cursor: 'pointer' }}
      />
    </Box>
  );

  const bodyComponent = (
    <Box sx={{ width: '100%', pl: '1em', pr: '1em' }}>
      <Box className="subHeader">
        <Typography className="ft-sm">
          {`${
            isDataSet ? 'Datasets' : 'Models'
          } saved will be available from your personal space`}
        </Typography>
        {transformOpen ? (
          <Box className="display-flex dark-fill-icon-no-hover">
            <Radio id="radioNewVersion" name="radioNewVersion" checked={true} />
            <label htmlFor="radioNewVersion">Create new version</label>
          </Box>
        ) : null}
      </Box>
      <AssetsForm
        modalController={modalController.type}
        inputControllers={{
          stateName: { name, setName, nameRef, validName, setValidName },
          stateDescription: { descriptionRef },
          stateAccess: { access, visibility, setVisibility },
          stateTags: [tags, setTags],
          shared: Boolean(sharedResources?.defaultValue)
        }}
        onEnter={onEnter}
        disableInputs={sendData}
        messageError={messageError}
      />
    </Box>
  );

  const footerComponent = (
    <>
      <CustomButton
        variant="secondary"
        disabled={sendData}
        onClick={closeModal}
      >
        Don&apos;t save
      </CustomButton>
      <CustomButton
        customRef={btnSave}
        disabled={!validName || sendData}
        onClick={handleSave}
      >
        {'Save'}
        {sendData && (
          <>
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ margin: '0px 10px' }}
            />
            <LoadingDots />{' '}
          </>
        )}
      </CustomButton>
    </>
  );

  return (
    <ModalComposed
      open={
        modalController.type === MODAL_TYPES.SAVE_MODEL ||
        modalController.type === MODAL_TYPES.SAVE_DATASET ||
        transformOpen
      }
      size={ModalSize.COMPOSED_FULL}
      header={headerComponent}
      body={bodyComponent}
      footer={footerComponent}
    />
  );
};

export default Save;
